import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTicketApi,
  makeFlightBooking,
  releasePNR,
} from "../../../modules/flight";
import { useLocation } from "react-router-dom";
import { handleLoader } from "../../../utils/slices/modifySearchFlightSlice";
import useStyles from "./styles";
import { Rings } from "react-loader-spinner";
import CartFareRules from "../../../components/flight-module/cards/selected-flight-fare-rules";
import CartFlightDetails from "../../../components/flight-module/cards/selected-flight-details";
import { Divider } from "@mui/material";
import moment from "moment";
import nationality from "../../../config/nationality.json";
import Coupon, {
  PrivateCoupon,
} from "../../../components/flight-module/cards/coupons";
import { toast } from "react-toastify";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import { setUserInfo } from "../../../utils/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import InfoSVG from "../../../assets/images/info-circle.svg";
import Tippy from "@tippy.js/react";
import CryptoJS from "crypto-js";
import { hotelValue, moduleValue, flightValue } from "../../../config";
import CardSelection from "../../../components/flight-module/search-flight/components/cards/card-selection";
import { useTranslation } from "react-i18next";
import eventBus from "../../../core/eventBus";
import { setBookingId } from "../../../utils/slices/bookingStatusSlice";
import EmailVerification from "../../../components/hotel-module/cards/Email-verification";

const FinalDetails = (props) => {
  const {
    flightDetails,
    insuranceDetails,
    totalAmount,
    convenienceFeeDetails,
    markup,
    markup2,
    convenienceFeeId,
    setConvenienceFeeId,
    basePrice,
    taxAmount,
    setScreenNo,
    isLCC,
    markupId,
    isCouponApplied,
    setIsCouponApplied,
    discountValue,
    setDiscountValue,
    message,
    setMessage,
    applyInsurance,
    extraBaggagePrice,
    seatPrice,
    tripType,
    couponDetails,
    resultIndex,
    extraBaggageDetails,
    seatsDetails,
    traceId,
    coupon,
    setCoupon,
    isInternational,
  } = props;

  const couponId = couponDetails?.lowestAmountCoupon ? couponDetails?.lowestAmountCoupon[0]?._id: ""
  
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.modifySearchFlight);
  const { selectedFlight } = useSelector((state) => state.bookedFlight);
  const defaultNational = useSelector((state) => state.personalDetails.defaultNational);
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [bookingId, setBookingId] = useState();
  const [baseAmount, setBaseAmount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [showVerificatonPopup, setShowEmailVerificationPopup] = useState(false);
  const [VATAmountSAR, setVATAmountSAR] = useState(0);
  const [dummy, setDummy] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  let resultIndexString = JSON.parse(resultIndex);
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  const islcc = JSON.parse(isLCC);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const { adultCount, childrenCount, infantCount } = JSON.parse(
    window.localStorage.getItem("flightTravellerDetails")
  );

  const paxDetails = JSON.parse(
    window.localStorage.getItem("flightTravellersDetails")
  );
  const [totalAmountPayable, settotalAmountPayable] = useState();
  const contactDetails = JSON.parse(
    window.localStorage.getItem("contactDetails")
  );
  const initialCountry = JSON.parse(localStorage.getItem("selectedCountry"));
  const { email, mobileNumber, cellCountryCode } = contactDetails;
  useEffect(() => {
    calculateTotalPayment();
    setConvenienceFeeId(convenienceFeeDetails[0]._id);
    getConvenienceFee(convenienceFeeDetails);
  }, [convenienceFee, totalAmount, baseAmount, convenienceFeeDetails]);

  const convertDate = (date) => {
    const parsedDate = moment(date, "DD/MM/YYYY");
    const formattedDateStr = parsedDate.format("YYYY-MM-DDTHH:mm:ss");
    return formattedDateStr;
  };

  const openCardSelectionPopUP = (bookingId) => {
    setFirstName(paxDetails[0].firstName);
    setLastName(paxDetails[0].lastName);
    eventBus.emit(
      "open-dialog",
      <CardSelection
        amount={payableAmount}
        firstName={paxDetails[0].firstName}
        lastName={paxDetails[0].lastName}
        email={contactDetails.email}
        bookingId={bookingId}
        product="FLIGHT"
        flightCouponId= {couponId}
        flightIsCouponApplied = {isCouponApplied}
      />
    );
  };

  const calculateTotalPayment = () => {
    let value = 0;
    value =
      Math.ceil(totalAmount / rateOfExchange) +
      Math.ceil(extraBaggagePrice) +
      Math.ceil(seatPrice) +
      Math.ceil(convenienceFee) +
      markup +
      markup2;

    let value2 = 0;

    value2 =
      Math.ceil(totalAmount) +
      Math.ceil(extraBaggagePrice * rateOfExchange) +
      Math.ceil(seatPrice * rateOfExchange) +
      Math.ceil(convenienceFee) +
      markup +
      markup2;

    if (applyInsurance) {
      value = value + Math.ceil(insuranceDetails?.amount);
      value2 = value2 + Math.ceil(insuranceDetails?.amount);
    }
    if (isCouponApplied) {
      value = value - discountValue;
      value2 = value2 - discountValue;
    }
    setPayableAmount(value2);
    return value;
  };

  const payloadForBooking = (ind) => {
    const {
      Segments,
      Fare: {
        BaseFare,
        Tax,
        TotalFare,
        OtherCharges,
        AgentMarkup,
        ServiceFee,
        VATAmount,
        VATPercentage,
      },
    } = selectedFlight[ind];
    // setVATAmount(VATAmount);

    let totalPax =
      Number(adultCount) + Number(childrenCount) + Number(infantCount);
    let passengerDetails = [];
    for (let i = 0; i < totalPax; i++) {
      passengerDetails.push([]);
    }
    // console.log("segmentsPayload", Segments);
    for (let i = 0; i < Number(adultCount); i++) {
      let adultPaxDetails = {};
      const {
        title,
        firstName,
        lastName,
        paxType,
        dateOfBirth,
        nationality,
        nationalityCode,
        passportNumber,
        expiryDate,
        documentDetailType,
        documentType,
        passportIssueDate,
        nationalIdExpiry,
        nationalNumber,
        iqamaNumber,
        iqamaIdExpiry,
        IdDetails
      } = paxDetails[i];
      const baggage = getBaggageForPax(i, "AD");

      const seat = getSeatsForPax(i);
      // const country = getCountryDetails(nationality);
      const leadPaxData = {
        Mobile1: mobileNumber,
        Email: email,
        Mobile1CountryCode: cellCountryCode,
      };
      adultPaxDetails = {
        Title: title,
        FirstName: firstName,
        LastName: lastName,
        Type: paxType,
        DateOfBirth: convertDate(dateOfBirth),
        PassportNo: passportNumber,
        PassportExpiry: convertDate(expiryDate),
        Gender: title == "Mr" ? 1 : 2,
        Fare: {
          BaseFare: BaseFare / totalPax,
          Tax: Tax / totalPax,
          TotalFare: TotalFare / totalPax,
          ServiceFee: ServiceFee / totalPax,
          OtherCharges: OtherCharges / totalPax,
          AgentMarkup: AgentMarkup / totalPax,
          VATAmount: VATAmount / totalPax,
          VATPercentage: VATPercentage,
        },
        Nationality: {
          CountryCode: nationalityCode ? nationalityCode : initialCountry.value,
          CountryName: nationality ? nationality : initialCountry.label,
        },
        Country: {
          CountryCode: nationalityCode ? nationalityCode : initialCountry.value,
          CountryName: nationality ? nationality : initialCountry.label,
        },
        City: {
          CountryCode: "SA",
          CityCode: "Riyadh",
          CityName: "Riyadh",
        },
        AddressLine1:
          "Office No 24, Ar Rabie Center, Near Mohammed Ibn Salman Ibn Abdulziz Road, Ar Rabi 6783,Riyadh, 13316",
        AddressLine2:
          "Office No 24, Ar Rabie Center, Near Mohammed Ibn Salman Ibn Abdulziz Road, Ar Rabi 6783,Riyadh, 13316",
        IsLeadPax: i == 0 ? true : false,
        FFAirline: Segments[0][0].Airline?.AirlineCode,
        FFNumber: Segments[0][0].Airline?.FlightNumber,
        CountryCode: "KSA",
        CountryName: "Saudi Arabia",
      };
      if (i == 0) {
        adultPaxDetails = {
          ...adultPaxDetails,
          ...leadPaxData,
        };
      }
      if (islcc[ind]) {
        adultPaxDetails = {
          ...adultPaxDetails,
          PaxBaggage: baggage,
          PaxSeat: seat,
        };
      }
      let idDetailsArray = [];
      let international = localStorage.getItem("flightInternational")
      let passportRegex = /^(?=.*[A-Za-z])(?=.*\d)/;
      let idType;
        if(international === false) {
        if(nationality !== "SA") {
          if (passportRegex.test(passportNumber)) {
            idType = 1;
        } else {
          idType = 3;
        }
        } else {
          if (passportRegex.test(passportNumber)) {
            idType = 1;
        }else {
          idType = 2;
        }
        }
      } else {
        idType = 1;
      }
      if(idType === 2) {
        idDetailsArray.push({
          IdType: 2,
          IdNumber: passportNumber,
          IssuedCountryCode: nationalityCode,
          ExpiryDate: convertDate(expiryDate),
          IssueDate: convertDate(passportIssueDate)
        });
      }      
      if (idType === 3) {
        idDetailsArray.push({
          IdType: 3,
          IdNumber: passportNumber,
          IssuedCountryCode: nationalityCode ? nationalityCode : initialCountry.value, 
          ExpiryDate: convertDate(expiryDate),
          IssueDate: convertDate(passportIssueDate)
        });
      }

      if (idType === 1) {
        idDetailsArray.push({
          IdType: 1,
          IdNumber: passportNumber,
          IssuedCountryCode: nationalityCode ? nationalityCode : initialCountry.value, 
          ExpiryDate: convertDate(expiryDate),
          IssueDate: convertDate(passportIssueDate)
        });
      }

      // if (passportNumber !== "") {
      //   adultPaxDetails = {
      //     ...adultPaxDetails,
      //     PassportNo: passportNumber,
      //     PassportExpiry: convertDate(expiryDate),
      //   };
      // } else {
      //   adultPaxDetails = {
      //     ...adultPaxDetails,
      //     PassportNo: "PP43254553",
      //     PassportExpiry: "2025-05-16T00:00:00",
      //   };
      // }

      // if (dateOfBirth) {
      //   adultPaxDetails = {
      //     ...adultPaxDetails,
      //     DateOfBirth: convertDate(dateOfBirth),
      //   };
      // }
      adultPaxDetails.IdDetails = idDetailsArray;
      passengerDetails[i] = adultPaxDetails;
    }

    for (let i = 0; i < Number(childrenCount); i++) {
      let childPaxDetails = {};
      const index = i + Number(adultCount);
      const {
        title,
        firstName,
        lastName,
        paxType,
        dateOfBirth,
        nationality,
        nationalityCode,
        passportNumber,
        documentDetailType,
        expiryDate,
        passportIssueDate,
        nationalIdExpiry,
        nationalNumber,
        iqamaNumber,
        iqamaIdExpiry,
        IdDetails
      } = paxDetails[index];
      const baggage = getBaggageForPax(i, "CH");
      const seat = getSeatsForPax(index);
      // const country = getCountryDetails(nationality);
      childPaxDetails = {
        Title: title,
        FirstName: firstName,
        LastName: lastName,
        Type: paxType,
        Gender: title == "Mr" ? 1 : 2,
        Fare: {
          BaseFare: BaseFare / totalPax,
          Tax: Tax / totalPax,
          TotalFare: TotalFare / totalPax,
          ServiceFee: ServiceFee / totalPax,
          OtherCharges: OtherCharges / totalPax,
          AgentMarkup: AgentMarkup / totalPax,
          VATAmount: VATAmount / totalPax,
          VATPercentage: VATPercentage,
        },
        Nationality: {
          CountryCode: nationalityCode ? nationalityCode : initialCountry.value,
          CountryName: nationality ? nationality : initialCountry.label,
        },
        Country: {
          CountryCode: nationalityCode ? nationalityCode : initialCountry.value,
          CountryName: nationality ? nationality : initialCountry.label,
        },
        City: {
          CountryCode: "SA",
          CityCode: "Riyadh",
          CityName: "Riyadh",
        },
        AddressLine1:
          "Office No 24, Ar Rabie Center, Near Mohammed Ibn Salman Ibn Abdulziz Road, Ar Rabi 6783,Riyadh, 13316",
        AddressLine2:
          "Office No 24, Ar Rabie Center, Near Mohammed Ibn Salman Ibn Abdulziz Road, Ar Rabi 6783,Riyadh, 13316",

        IsLeadPax: i == 0 ? true : false,
        FFAirline: Segments[0][0].Airline?.AirlineCode,
        FFNumber: Segments[0][0].Airline?.FlightNumber,
        CountryCode: "KSA",
        CountryName: "Saudi Arabia",
        DateOfBirth: convertDate(dateOfBirth),
      };
      if (islcc[ind]) {
        childPaxDetails = {
          ...childPaxDetails,
          Baggage: baggage,
          SeatDynamic: seat,
        };
      }
      let idDetailsArray = [];
      let international = localStorage.getItem("flightInternational")
      let passportRegex = /^(?=.*[A-Za-z])(?=.*\d)/;
      let idType;
        if(international === false) {
        if(nationality !== "SA") {
          if (passportRegex.test(passportNumber)) {
            idType = 1;
        } else {
          idType = 3;
        }
        } else {
          if (passportRegex.test(passportNumber)) {
            idType = 1;
        }else {
          idType = 2;
        }
        }
      } else {
        idType = 1;
      }
      if(idType === 2) {
        idDetailsArray.push({
          IdType: 2,
          IdNumber: passportNumber,
          IssuedCountryCode: nationalityCode,    
          ExpiryDate: convertDate(expiryDate),
          IssueDate: convertDate(passportIssueDate)
        });
      }
      if (idType === 3) {
        idDetailsArray.push({
          IdType: 3,
          IdNumber: passportNumber,
          IssuedCountryCode: nationalityCode ? nationalityCode : initialCountry.value,
          ExpiryDate: convertDate(expiryDate),
          IssueDate: convertDate(passportIssueDate)
        });
      }

      if (idType === 1) {
        idDetailsArray.push({
          IdType: 1,
          IdNumber: passportNumber,
          IssuedCountryCode: nationalityCode ? nationalityCode : initialCountry.value, 
          ExpiryDate: convertDate(expiryDate),
          IssueDate: convertDate(passportIssueDate)
        });
      }
      if (passportNumber !== "") {
        childPaxDetails = {
          ...childPaxDetails,
          PassportNo: passportNumber,
          PassportExpiry: convertDate(expiryDate),
          PassportIssueDate: convertDate(passportIssueDate),
        };
      }
      childPaxDetails.IdDetails = idDetailsArray;
      passengerDetails[index] = childPaxDetails;
    }

    for (let i = 0; i < Number(infantCount); i++) {
      let infantPaxDetails = {};
      const index = i + Number(adultCount) + Number(childrenCount);
      const {
        title,
        firstName,
        lastName,
        paxType,
        dateOfBirth,
        nationality,
        nationalityCode,
        passportNumber,
        expiryDate,
        documentDetailType,
        passportIssueDate,
        nationalIdExpiry,
        nationalNumber,
        iqamaNumber,
        iqamaIdExpiry,
        IdDetails
      } = paxDetails[index];
      // const country = getCountryDetails(nationality);
      infantPaxDetails = {
        Title: title,
        FirstName: firstName,
        LastName: lastName,
        Type: paxType,
        Gender: title == "Mr" ? 1 : 2,
        Fare: {
          BaseFare: BaseFare / totalPax,
          Tax: Tax / totalPax,
          TotalFare: TotalFare / totalPax,
          ServiceFee: ServiceFee / totalPax,
          OtherCharges: OtherCharges / totalPax,
          AgentMarkup: AgentMarkup / totalPax,
          VATAmount: VATAmount / totalPax,
          VATPercentage: VATPercentage,
        },
        Nationality: {
          CountryCode: nationalityCode ? nationalityCode : initialCountry.value,
          CountryName: nationality ? nationality : initialCountry.label,
        },
        Country: {
          CountryCode: nationalityCode ? nationalityCode : initialCountry.value,
          CountryName: nationality ? nationality : initialCountry.label,
        },
        City: {
          CountryCode: "SA",
          CityCode: "Riyadh",
          CityName: "Riyadh",
        },
        AddressLine1:
          "Office No 24, Ar Rabie Center, Near Mohammed Ibn Salman Ibn Abdulziz Road, Ar Rabi 6783,Riyadh, 13316",
        AddressLine2:
          "Office No 24, Ar Rabie Center, Near Mohammed Ibn Salman Ibn Abdulziz Road, Ar Rabi 6783,Riyadh, 13316",

        IsLeadPax: i == 0 ? true : false,
        FFAirline: Segments[0][0].Airline?.AirlineCode,
        FFNumber: Segments[0][0].Airline?.FlightNumber,
        CountryCode: "KSA",
        CountryName: "Saudi Arabia",
        DateOfBirth: convertDate(dateOfBirth),
      };
      let idDetailsArray = [];
      let international = localStorage.getItem("flightInternational")
      let passportRegex = /^(?=.*[A-Za-z])(?=.*\d)/;
      let idType;
        if(international === false) {
        if(nationality !== "SA") {
          if (passportRegex.test(passportNumber)) {
            idType = 1;
        } else {
          idType = 3;
        }
        } else {
          if (passportRegex.test(passportNumber)) {
            idType = 1;
        }else {
          idType = 2;
        }
        }
      } else {
        idType = 1;
      }
      if(idType === 2) {
        idDetailsArray.push({
          IdType: 2,
          IdNumber: passportNumber,
          ExpiryDate: convertDate(expiryDate),
          IssueDate: convertDate(passportIssueDate)
        });
      }
      if (idType === 3) {
        idDetailsArray.push({
          IdType: 3,
          IdNumber: passportNumber,
          IssuedCountryCode: nationalityCode ? nationalityCode : initialCountry.value, 
          ExpiryDate: convertDate(expiryDate),
          IssueDate: convertDate(passportIssueDate)
        });
      }

      if (idType === 1) {
        idDetailsArray.push({
          IdType: 1,
          IdNumber: passportNumber,
          IssuedCountryCode: nationalityCode ? nationalityCode : initialCountry.value, 
          ExpiryDate: convertDate(expiryDate),
          IssueDate: convertDate(passportIssueDate)
        });
      }
      infantPaxDetails.IdDetails = idDetailsArray;
      passengerDetails[index] = infantPaxDetails;
    }

    return passengerDetails;
  };

  const getCountryDetails = (value) => {
    const foundCountry = nationality.find((data) => data.nationality == value);
    return foundCountry;
  };

  const getBaggageForPax = (id, type) => {
    let baggageForPax = [];
    for (const key in extraBaggageDetails) {
      const baggage = extraBaggageDetails[key];
      for (let j = 0; j < baggage.length; j++) {
        if (baggage[j].type == type && baggage[j].id == id) {
          delete baggage[j].id;
          delete baggage[j].type;
          baggageForPax.push(baggage[j]);
        }
      }
    }

    return baggageForPax;
  };

  const getSeatsForPax = (id) => {
    let seatForPax = [];
    for (const key in seatsDetails) {
      const seats = seatsDetails[key];
      for (let j = 0; j < seats.length; j++) {
        if (j == id) {
          seatForPax.push(seats[j]);
        }
      }
    }

    return seatForPax;
  };

  const handleReleasePNR = async (bookingId) => {
    const data = await releasePNR({
      payload: { booking: bookingId, source: selectedFlight?.[0]?.Source },
      token: user?.token,
    });
    if (data?.releasePnr == "Success") {
      setIsButtonDisabled(false);
    }
  };

  const handleEncrypt = (payloadData) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(payloadData),
      CryptoJS.enc.Utf8.parse(moduleValue + flightValue + hotelValue + "e3n"),
      {
        mode: CryptoJS.mode.ECB,
      }
    ).toString();
    return encryptedData;
  };

  const makePayment = async () => {
    setIsButtonDisabled(true);
    dispatch(handleLoader(true));
    // const indices = resultIndex[0].toString();
    const TotalPayableAmount = calculateTotalPayment();

    let flightTickets = [];

    const obj = islcc
      ? {
        isLCC: true,
        lccFlight: {
          ResultIndex: resultIndexString.toString(),
          Passengers: payloadForBooking(0),
          IPAddress: "198.162.1.12",
          Segments: selectedFlight,
          isInternational: !isInternational,
        },
        nonLCCFlight: {},
      }
      : {
        isLCC: false,
        lccFlight: {},
        nonLCCFlight: {
          ResultIndex: resultIndexString.toString(),
          Passengers: payloadForBooking(0),
          IPAddress: "198.162.1.12",
          Segments: selectedFlight,
          isInternational: !isInternational,
        },
      };

    flightTickets.push(obj);
    console.log('rateOfExchange', rateOfExchange)
    const data = {
      TraceId: traceId,
      TotalPayableAmount: (TotalPayableAmount / rateOfExchange).toString(),
      TotalAmountSAR: payableAmount,
      baggageAmountSAR: Math.ceil(extraBaggagePrice * rateOfExchange),
      seatAmountSAR: Math.ceil(seatPrice * rateOfExchange),
      convenienceFeeAmountSAR: Math.ceil(convenienceFee),
      taxSAR: Math.ceil( taxAmount * rateOfExchange),
      baseFareSAR: basePrice,
      vatAmountSAR: Math.ceil(VATAmountSAR * rateOfExchange),
      Currency: "USD",
      mobileNumber: mobileNumber,
      convenienceFee: convenienceFeeId,
      convenienceFeeAmount: Math.ceil(convenienceFee),
      markup: markupId,
      markupPrice: Math.ceil(markup + markup2),
      coupon: isCouponApplied ? couponDetails.lowestAmountCoupon[0]._id : "",
      couponDiscount: isCouponApplied ? Math.ceil(discountValue) : 0,
      baggageAmount: extraBaggagePrice,
      seatAmount: seatPrice,
      insurance: {
        adult:
          Number(adultCount) > 0 && applyInsurance
            ? getInsuranceIdBasedOnPaxType(1)
            : "",
        child:
          Number(childrenCount) > 0 && applyInsurance
            ? getInsuranceIdBasedOnPaxType(2)
            : "",
        infant:
          Number(infantCount) > 0 && applyInsurance
            ? getInsuranceIdBasedOnPaxType(3)
            : "",
      },
      insuranceAmount: applyInsurance ? Math.ceil(insuranceDetails?.amount) : 0,
      // taxAmount: totalAmount / rateOfExchange - basePrice / rateOfExchange,
      taxAmount: taxAmount,
      flightTickets,
    };
    console.log('Payload', data)
    const encryptedData = handleEncrypt(data);

    const response = await makeFlightBooking(encryptedData, user?.token);
    const itemsToRemove = [
      'documentType',
      'documentDetailType',
      'passportDetails',
      'nationalDetails',
      'showNationals',
      'countryCode'
        ];
    
    itemsToRemove.forEach(item => localStorage.removeItem(item));
    // console.log("windowObject", window);
    const statusCode = response?.data?.statusCode;
    const message = response?.message;
    setIsButtonDisabled(false);
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }
    if (response.statusCode == 402) {
      setShowEmailVerificationPopup(true);
    }
    if (statusCode === 200) {
      const res = response.data.data;
      // dispatch(setBookingId(res?.booking?._id.toString()));
      // setBookingId(res?.booking?._id.toString());
      openCardSelectionPopUP(res?.booking?._id.toString());
      // if (res?.flow_config) {
      //   var config = {
      //     responseHandler: (response) => {
      //       if (response?.status == 111 && response?.txnResponse == undefined) {
      //         handleReleasePNR(res?.booking?._id);
      //       }
      //     },
      //     merchantLogo: "",
      //     flowConfig: res.flow_config,
      //     flowType: "payments",
      //   };
      //   // window.loadBillDeskSdk(config);

      // }
      // const data = {
      //   bookingId: res?.booking?._id.toString(),
      // };

      // const ticketResponse = await getTicketApi(data);
      // const bookingId = ticketResponse.data.bookingId;
      // const paymentStatus = ticketResponse.data.paymentStatus;
      // if (ticketResponse.statusCode == 200) {
      //   navigate(`/flight/bookingConfirmation/${bookingId}/${paymentStatus}`);
      // }

      dispatch(handleLoader(false));
    } else {
      dispatch(handleLoader(false));
      // toast.error(`Error while booking ticket. Please try again!`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getInsuranceIdBasedOnPaxType = (paxType) => {
    const passengerType =
      paxType === 1 ? "ADULT" : paxType === 2 ? "CHILDREN" : "INFANT";
    const insurance = insuranceDetails?.insurances.find(
      (insurance) => insurance.passengerType === passengerType
    );
    return insurance._id;
  };

  const getConvenienceFee = (convenienceFeeDetails) => {
    let value = 0;
    // for (let i = 0; i < convenienceFeeDetails?.length; i++) {
    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount, infantCharge } =
      convenienceFeeDetails[0];
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        amt = (amt * (baseAmount + markup + markup2)) / 100;
      } else {
        amt = (amt * (totalAmount + markup + markup2)) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    value = value < amt ? amt : value;

    if (amountType === "FLAT")
      if (infantCharge == true) {
        value =
          value *
          (Number(adultCount) + Number(childrenCount) + Number(infantCount));
      } else {
        value = value * (Number(adultCount) + Number(childrenCount));
      }

    // }

    setConvenienceFee(value);
  };

  useEffect(() => {
    calculateTotalPayment();
  }, [insuranceDetails, isCouponApplied]);
  const visibility = couponDetails?.lowestAmountCoupon
    ? couponDetails?.lowestAmountCoupon[0]?.visibility
    : "";

  useEffect(() => {
    if (!VATAmountSAR) {
      const {
        Fare: {
          VATAmount,
        }
      } = selectedFlight[0];
      setVATAmountSAR(VATAmount);
    }
  }, [selectedFlight])
  return (
    <>
      {loading ? (
        <div className={classes.loader}>
          <Rings
            height="150"
            width="150"
            color="#1B1D52"
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <div className={classes.outerContainer}>
            <div className={classes.headingContainer}>
              <h2 className={classes.mainHeading}>
                {t("finalDetails.completeYourBooking")}
              </h2>
              <p
                className={
                  isMobile ? classes.editDetailsMobile : classes.editDetails
                }
                onClick={() => setScreenNo(1)}
              >
                {t("finalDetails.editBookingDetails")}
              </p>
            </div>
            <div className={classes.FlightSummaryContainer}>
              {flightDetails &&
                flightDetails.map((flight, i) => {
                  return (
                    <div className={classes.flightDetailCard}>
                      <CartFlightDetails
                        flight={flight}
                        key={i}
                        selected={i}
                        markup={
                          tripType === "DRT"
                            ? i == 0
                              ? markup
                              : markup2
                            : markup
                        }
                        source={"final"}
                      />
                    </div>
                  );
                })}
            </div>
            <div className={classes.contentContainer}>
              {tripType === "DRT" ? (
                <>
                  <div className={classes.drtMainContainer}>
                    {flightDetails &&
                      flightDetails.map((flight, i) => {
                        return (
                          <>
                            <CartFareRules
                              flight={flight}
                              key={i}
                              insurance={insuranceDetails}
                              applyInsurance={applyInsurance}
                              setTotalAmount={setDummy}
                              setBaseAmount={setBaseAmount}
                              showWay={true}
                              calculateExtraBaggagePriceValue={
                                extraBaggagePrice
                              }
                              markup={
                                tripType === "DRT"
                                  ? i == 0
                                    ? markup
                                    : markup2
                                  : markup
                              }
                            />
                            {i === 0 && (
                              <Divider sx={{ backgroundColor: "black" }} />
                            )}
                          </>
                        );
                      })}
                    {applyInsurance && insuranceDetails && (
                      <div className={classes.insuranceContainer}>
                        <p className={classes.tripInsurance}>
                          {t("finalDetails.tripInsurance")}
                        </p>
                        <p className={classes.tripInsuranceAmount}>
                          {t("finalDetails.currencySymbol")}{" "}
                          {insuranceDetails.amount.toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}
                    {extraBaggagePrice > 0 && (
                      <div className={classes.baggageContainer}>
                        <p className={classes.extraBaggage}>
                          {t("finalDetails.extraBaggage")}
                        </p>
                        <p className={classes.extraBaggagePrice}>
                          {t("finalDetails.currencySymbol")}{" "}
                          {Math.ceil(
                            extraBaggagePrice * rateOfExchange
                          ).toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}
                    {seatPrice > 0 && (
                      <div className={classes.baggageContainer}>
                        <p className={classes.extraBaggage}>
                          {t("finalDetails.extraSeatPrice")}
                        </p>
                        <p className={classes.extraBaggagePrice}>
                          {t("finalDetails.currencySymbol")}{" "}
                          {seatPrice.toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}
                    {isCouponApplied && (
                      <div className={classes.insuranceContainer}>
                        <p className={classes.tripInsurance}>
                          {t("finalDetails.discount")}
                        </p>
                        <p className={classes.tripInsuranceAmount}>
                          - {t("finalDetails.currencySymbol")}{" "}
                          {discountValue.toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}
                    {convenienceFee > 0 && (
                      <div className={classes.baggageContainer}>
                        <Tippy
                          arrow={true}
                          placement="bottom-start"
                          animation="shift-away"
                          content={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "12px",
                                padding: "16px",
                                background: "#344054",
                                borderRadius: "4px",
                                width: "250px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "#FFF",
                                  fontWeight: 500,
                                }}
                              >
                                {t("finalDetails.nonRefundableConvenienceFee")}{" "}
                                {t("finalDetails.currencySymbol")}{" "}
                                {Math.ceil(
                                  convenienceFee /
                                  (adultCount + childrenCount + infantCount)
                                ).toLocaleString("en-IN")}{" "}
                                {t("finalDetails.perTraveller")}
                              </p>
                            </div>
                          }
                        >
                          <p
                            className={classes.extraBaggage}
                            style={{
                              display: "inline-flex",
                              gap: "4px",
                              marginTop: "2px",
                            }}
                          >
                            {t("finalDetails.convenienceFee")}
                            <ReactSVG src={InfoSVG} />
                          </p>
                        </Tippy>

                        <p className={classes.extraBaggagePrice}>
                          {t("finalDetails.currencySymbol")}{" "}
                          {convenienceFee.toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}
                    <Divider mt={3} md={3} sx={{ borderStyle: "dashed" }} />

                    {totalAmount > 0 && (
                      <div className={classes.drtPriceContainer}>
                        <p className={classes.drtTotalPriceText}>
                          {t("finalDetails.totalPrice")}
                        </p>
                        <p className={classes.drtPrice}>
                          {" "}
                          {totalAmount > 0 &&
                            `${t(
                              "finalDetails.currencySymbol"
                            )} ${payableAmount.toLocaleString("en-IN")}`}
                        </p>
                      </div>
                    )}
                  </div>
                  <PrivateCoupon
                    couponDetails={couponDetails}
                    traceId={traceId}
                    setIsCouponApplied={setIsCouponApplied}
                    tripType={tripType}
                    flightDetails={flightDetails}
                    markup={markup}
                    markup2={markup2}
                    setDiscountValue={setDiscountValue}
                    isCouponApplied={isCouponApplied}
                    message={message}
                    setMessage={setMessage}
                    visibility={visibility}
                    coupon={coupon}
                    setCoupon={setCoupon}
                  />
                </>
              ) : (
                flightDetails &&
                flightDetails?.length > 0 && (
                  <>
                    <div className={classes.cartFairRulesAndPriceContainer}>
                      {
                        <CartFareRules
                          markup={
                            tripType === "DRT"
                              ? i == 0
                                ? markup
                                : markup2
                              : markup
                          }
                          flight={flightDetails[0]}
                          insurance={insuranceDetails}
                          applyInsurance={applyInsurance}
                          setTotalAmount={setDummy}
                          setBaseAmount={setBaseAmount}
                          seatPrice={seatPrice}
                          showWay={false}
                          calculateExtraBaggagePriceValue={extraBaggagePrice}
                        />
                      }
                      {applyInsurance && insuranceDetails && (
                        <div className={classes.insuranceContainer}>
                          <p className={classes.tripInsurance}>
                            {t("finalDetails.tripInsurance")}
                          </p>
                          <p className={classes.tripInsuranceAmount}>
                            {t("finalDetails.currencySymbol")}{" "}
                            {insuranceDetails.amount.toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                      {extraBaggagePrice > 0 && (
                        <div className={classes.baggageContainer}>
                          <p className={classes.extraBaggage}>
                            {t("finalDetails.extraBaggage")}
                          </p>
                          <p className={classes.extraBaggagePrice}>
                            {t("finalDetails.currencySymbol")}{" "}
                            {Math.ceil(
                              extraBaggagePrice * rateOfExchange
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                      {seatPrice > 0 && (
                        <div className={classes.baggageContainer}>
                          <p className={classes.extraBaggage}>
                            {t("finalDetails.extraSeatPrice")}
                          </p>
                          <p className={classes.extraBaggagePrice}>
                            {t("finalDetails.currencySymbol")}{" "}
                            {Math.ceil(
                              seatPrice * rateOfExchange
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                      {isCouponApplied && (
                        <div className={classes.insuranceContainer}>
                          <p className={classes.tripInsurance}>
                            {t("finalDetails.discount")}
                          </p>
                          <p className={classes.tripInsuranceAmount}>
                            - {t("finalDetails.currencySymbol")}{" "}
                            {discountValue.toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                      {convenienceFee > 0 && (
                        <div className={classes.baggageContainer}>
                          <Tippy
                            arrow={true}
                            placement="bottom-start"
                            animation="shift-away"
                            content={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "12px",
                                  padding: "16px",
                                  background: "#344054",
                                  borderRadius: "4px",
                                  width: "250px",
                                  marginTop: "-20px",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "#FFF",
                                    fontWeight: 500,
                                  }}
                                >
                                  {t(
                                    "finalDetails.nonRefundableConvenienceFee"
                                  )}{" "}
                                  {t("finalDetails.currencySymbol")}{" "}
                                  {Math.ceil(
                                    convenienceFee /
                                    (adultCount + childrenCount + infantCount)
                                  ).toLocaleString("en-IN")}{" "}
                                  {t("finalDetails.perTraveller")}
                                </p>
                              </div>
                            }
                          >
                            <p
                              className={classes.extraBaggage}
                              style={{
                                display: "inline-flex",
                                gap: "4px",
                                marginTop: "2px",
                              }}
                            >
                              {t("finalDetails.convenienceFee")}
                              <ReactSVG src={InfoSVG} />
                            </p>
                          </Tippy>
                          <p className={classes.extraBaggagePrice}>
                            {t("finalDetails.currencySymbol")}{" "}
                            {Math.ceil(convenienceFee).toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                      <Divider mt={3} md={3} sx={{ borderStyle: "dashed" }} />
                      {totalAmount > 0 && (
                        <div className={classes.totalPriceContainer}>
                          <p className={classes.totalPriceText}>
                            {t("finalDetails.totalPrice")}
                          </p>
                          <p className={classes.price}>
                            {totalAmount > 0 &&
                              `${t("finalDetails.currencySymbol")} ${Math.ceil(
                                payableAmount
                              ).toLocaleString("en-IN")}`}
                          </p>
                        </div>
                      )}
                    </div>
                    <PrivateCoupon
                      couponDetails={couponDetails}
                      traceId={traceId}
                      setIsCouponApplied={setIsCouponApplied}
                      tripType={tripType}
                      flightDetails={flightDetails}
                      markup={markup}
                      markup2={markup2}
                      setDiscountValue={setDiscountValue}
                      isCouponApplied={isCouponApplied}
                      message={message}
                      setMessage={setMessage}
                      visibility={visibility}
                      coupon={coupon}
                      setCoupon={setCoupon}
                    />
                    <EmailVerification
                      showVerificatonPopup={showVerificatonPopup}
                      setShowEmailVerificationPopup={
                        setShowEmailVerificationPopup
                      }
                    />
                  </>
                )
              )}
            </div>
            <div className={classes.finalPaymentContainer}>
              <h3 className={classes.finalPaymentTotalPrice}>
                {t("finalDetails.continueToFinalDetails")}
              </h3>
              <div
                className={
                  isButtonDisabled
                    ? classes.payNowButtonDisabled
                    : classes.payNowButton
                }
                onClick={() => {
                  if (!isButtonDisabled) {
                    makePayment();
                  }
                }}
              >
                <p className={classes.payNowText}>
                  {" "}
                  {t("finalDetails.payNow")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FinalDetails;
