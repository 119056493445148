import React, { useEffect, useState } from "react";
import {
  getFlightCoupons,
  getFlightDetails,
  getInsuranceDetails,
  getMarkupDetails,
  getSsrDetails,
} from "../../../modules/flight";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setUserInfo } from "../../../utils/slices/userSlice";
import { handleLoader } from "../../../utils/slices/modifySearchFlightSlice";
import { handleSelectedFlightGlobal } from "../../../utils/slices/bookedFlightSlice";
import { formatDateToDdMmYyyy } from "../../../utils/helpers";
import SessionExpired from "../../../components/flight-module/cards/session-expired";
import CartFlightDetails from "../../../components/flight-module/cards/selected-flight-details";
import useStyles from "./style";
import ChangePrice from "../../../components/flight-module/cards/change-price-modal";
import FlightStepper from "../../../components/flight-module/stepper";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import Coupon, {
  PrivateCoupon,
} from "../../../components/flight-module/cards/coupons";
import CartFareRules from "../../../components/flight-module/cards/selected-flight-fare-rules";
import { Divider } from "@mui/material";
import { Rings } from "react-loader-spinner";
import PersonalDetails from "../personal-details";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SeatDetails from "../seat-details";
import FinalDetails from "../final-details";
import { v4 as uuidv4 } from "uuid";
import FallBackScreen from "../../../components/home/fallback-screen";
import { useTranslation } from "react-i18next";
import { setRate } from "../../../utils/slices/rateOfExchangeSlice";

const BookFlightDetailsFlow = () => {
  const styles = useStyles();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [uuid, setUuid] = useState();

  // const {
  //   flightFrom,
  //   flightTo,
  //   flightDepartureDate,
  //   flightTravelType,
  //   flightClassType,
  // } = useSelector((state) => state.modifySearchFlight);
  const user = useSelector((state) => state.user);
  const { redirectScreen } = useSelector((state) => state.popup);
  const { selectedFlight } = useSelector((state) => state.bookedFlight);
  const isUUIDStored = () => {
    return localStorage.getItem("uuid");
  };

  const generateAndStoreUUID = () => {
    if (!isUUIDStored()) {
      const newUUID = uuidv4();
      localStorage.setItem("uuid", newUUID);
    }

    setUuid(localStorage.getItem("uuid"));
  };

  useEffect(() => {
    generateAndStoreUUID();
  }, []);

  // const { multiCityDates, flightReturnDate } = JSON.parse(
  //   window.localStorage.getItem("modifySearchFlightDetails")
  // );

  const additionalDetails = {
    deviceType: "WEB",
    browserType: "CHROME",
    ipAddress: "192.168.1.68",
    uuid: uuid,
  };

  const {
    traceId,
    resultIndex,
    isLcc,
    totalTrips,
    tripType,
    adults,
    children,
    infants,
    pf,
    markupId,
    cabinClass,
    airlines,
  } = useParams();

  const flightFrom = searchParams.get("ff");
  const flightTo = searchParams.get("ft");
  const flightDepartureDate = searchParams.get("dd");
  const flightReturnDate = searchParams.get("rd");
  const multiCityDate1 = searchParams.get("md1");
  const multiCityDate2 = searchParams.get("md2");
  const isInternational = searchParams.get("isIt");

  const [screenNo, setScreenNo] = useState(
    redirectScreen?.screenNo ? redirectScreen.screenNo : 1
  );
  const [seats, setSeats] = useState([]);
  const [dummy, setDummy] = useState(0);
  const [seatPrice, setSeatPrice] = useState(0);
  const [additionalBaggageDetails, setAdditionalBaggageDetails] = useState([]);
  const [extraBaggage, setExtraBaggage] = useState([]);
  const [baseAmount, setBaseAmount] = useState(0);
  const [calculateExtraBaggagePriceValue, setCalculateExtraBaggagePriceValue] =
    useState(0);

  const [insuranceDetails, setInsuranceDetails] = useState({});
  const [hasSeatDynamic, setHasSeatDynamic] = useState(false);
  const [segmentSeats, setSegmentSeats] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [flightDetails, setFlightDetails] = useState(selectedFlight);
  const [selectedDate, setSelectedDate] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [markup, setMarkup] = useState(0);
  const [markup2, setMarkup2] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [airlineCodes, setAirlineCodes] = useState([]);
  const [applyInsurance, setApplyInsurance] = useState(false);
  const [isPassportRequired, setIsPassportRequired] = useState(false);
  const [message, setMessage] = useState("");
  const [discountValue, setDiscountValue] = useState(0);
  const [showChangePriceModal, setShowChangePriceModal] = useState(false);
  const [changedFareValue, setChangedFareValue] = useState([]);
  const [couponDetails, setCouponDetails] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [basePrice, setBasePrice] = useState(0);
  const [showPriceAccordian, setShowPriceAccordian] = useState(false);
  const [convenienceFeeDetails, setConvenienceFeeDetails] = useState({});
  const [convenienceFeeId, setConvenienceFeeId] = useState("");
  const [seatsArrayBackup, setSeatsArrayBackup] = useState([]);
  const [flightNumbers, setFlightNumbers] = useState([]);
  const [taxAmount, setTaxAmout] = useState()
  const rateOfExchange = useSelector((state) => state?.rateOfExchange?.rate);
  const [rateOfExchangeUpdated, setRateOfExchangeUpdated] =
    useState(rateOfExchange);

  const handleClearCouponValue = () => {
    setCoupon("");
    setMessage("");
    setIsCouponApplied(false);
    setDiscountValue(0);
  };
  const { t } = useTranslation();
  const [steps, setSteps] = useState([
    `${t("stepper.chooseYourFlight")}`,
    `${t("stepper.enterYourDetails")}`,
    `${t("stepper.addOns")}`,
    `${t("stepper.reviewBooking")}`,
  ]);
  const totalCount = Number(adults) + Number(children) + Number(infants);

  const visibility = couponDetails?.lowestAmountCoupon
    ? couponDetails?.lowestAmountCoupon[0]?.visibility
    : "";
  useEffect(() => {
    generateAndStoreUUID();
    if (uuid) {
      handleGetFlightDetails();
      handleGetInsuranceDetails();
      addTravellersDetailsToLocalStorage();
    }
  }, [uuid, user]);

  useEffect(() => {
    setRateOfExchangeUpdated(rateOfExchange);
  }, [rateOfExchange]);

  useEffect(() => {
    if (redirectScreen?.screenNo) setScreenNo(redirectScreen.screenNo);
  }, [redirectScreen]);

  const addTravellersDetailsToLocalStorage = () => {
    const travellerDetails = {
      totalTravellers: totalCount,
      adultCount: Number(adults),
      childrenCount: Number(children),
      infantCount: Number(infants),
    };
    window.localStorage.setItem(
      "flightTravellerDetails",
      JSON.stringify(travellerDetails)
    );
  };

  const handleGetCoupons = async (
    amount,
    markup,
    markup2,
    base,
    airlineCodesArray
  ) => {
    const paxType = [];
    if (Number(adults) > 0) {
      paxType.push("ADULT");
    }
    if (Number(children) > 0) {
      paxType.push("CHILDREN");
    }
    if (Number(infants) > 0) {
      paxType.push("INFANT");
    }

    const payload = makePayloadForCoupons(
      paxType,
      amount,
      markup,
      markup2,
      base,
      airlineCodesArray
    );
    const res = await getFlightCoupons(payload, user?.token);
    setCouponDetails(res);
    if(isCouponApplied && res?.lowestAmountCoupon && res.lowestAmountCoupon.length == 0){
      handleClearCouponValue()
    }
  };

  const makePayloadForCoupons = (
    paxType,
    amount,
    markup,
    markup2,
    base,
    airlineCodesArray
  ) => {
    const payload = {
      origin:
        tripType == "DRT" || tripType == "IRT"
          ? [flightFrom, flightTo]
          : [flightFrom],
      destination:
        tripType == "DRT" || tripType == "IRT"
          ? [flightFrom, flightTo]
          : [flightTo],
      travelDate: flightDepartureDate,
      bookingDate: formatDateToDdMmYyyy(new Date()),
      travelType:
        tripType == "OWT"
          ? "1"
          : tripType == "DRT" || tripType == "IRT"
            ? "2"
            : "3",
      travelClass: cabinClass.toString(),
      paxType,
      paxCount: Number(adults) + Number(children) + Number(infants),
      txnAmount: amount + markup + markup2,
      baseFare: base + markup + markup2,
      TotalFare: amount + markup + markup2,
      airlineCode: airlineCodesArray,

      day: new Date().getDay(),
      routeType: isInternational == "true" ? "INTERNATIONAL" : "DOMESTIC",
    };

    return payload;
  };

  const handleGetInsuranceDetails = async () => {
    const data = await getInsuranceDetails({
      adultCount: Number(adults),
      childrenCount: Number(children),
      infantCount: Number(infants),
      token: user?.token,
    });
    setInsuranceDetails(data);
    setFlightDetails(selectedFlight);
  };

  const SetRateOfExchange = (data) => {
    dispatch(setRate(data));
  };
  const handleGetFlightDetails = async () => {
    const result = await getFlightDetails({
      traceId,
      resultIndex,
      additionalDetails,
      token: user?.token,
      cabinClass,
      airlines,
      pf,
      SetRateOfExchange,
    });

    const statusCode = result?.statusCode;
    const message = "Something went wrong";

    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    } else if (statusCode == 400) {
      setSessionExpired(true);
      setErrorMessage(message);
      dispatch(handleLoader(false));
    } else if (result?.res) {
      if (result?.convenienceFeeDetails)
        setConvenienceFeeDetails(result?.convenienceFeeDetails);
      dispatch(handleLoader(false));

      const markupDetails = await handleGetMarkupDetails();
      if (tripType === "MCT") {
        handleSelectedFlightForMultiCity(
          result?.res[0],
          markupDetails,
          result?.rateOfExchange
        );
      } else if (tripType === "OWT") {
        handleSelectedFlightForOneWay(
          result?.res[0],
          markupDetails,
          result?.rateOfExchange
        );
      } else if (tripType === "IRT") {
        handleSelectedFlightForInternationalRoundTrip(
          result?.res[0],
          markupDetails,
          result?.rateOfExchange
        );
      } else if (tripType === "DRT") {
        // handleSelectedFlightForDomesticRoundTrip(result?.res, markupDetails);
      }
      // handleSelectedFlightForOneWayTEMP(result?.res[0]);

      handleGetSSRdetails();
    } else {
      setServerError(true);
    }
  };
  const getMarkup = (flight, markupDetails, rateOfExchange) => {
    if (!markupDetails || Object.keys(markupDetails).length === 0) {
      return;
    }
    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;

    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        let baseFare =
          flight?.Fare?.TotalFare -
          (flight?.Fare?.Tax + flight?.Fare?.VATAmount);

        amt =
          (amt * (baseFare * rateOfExchange + flight?.Fare?.OtherCharges)) /
          100;
      } else {
        amt = (amt * (flight?.Fare?.TotalFare * rateOfExchange)) / 100;
      }
    }

    amt = maxAmount < amt ? maxAmount : amt;
    amt = Math.ceil(amt);

    return amt;
  };
  const handleGetMarkupDetails = async () => {
    const res = await getMarkupDetails(markupId);
    return res?.data;
  };

  const handleSelectedFlightForMultiCity = (
    flight,
    markupDetails,
    rateOfExchange
  ) => {
    const data = flight[0];
    const markup = getMarkup(data, markupDetails, rateOfExchange) || 0;
    setMarkup(markup);
    const amount = Math.ceil(data?.Fare?.TotalFare * rateOfExchange);

    const base = Math.ceil(
      Math.ceil(data?.Fare?.TotalFare * rateOfExchange) -
      Math.ceil(data?.Fare?.Tax) +
      Math.ceil(data?.Fare?.VATAmount)
    );
    const airlineCodesArray = [data.Segments[0][0].AirlineDetails.AirlineCode];
    setAirlineCodes([data.ValidatingAirline]);
    setFlightNumbers([data.Segments[0][0].Airline.FlightNumber]);
    const updatedFare = [
      Math.ceil(data?.Fare?.TotalFare * rateOfExchange) + markup,
    ];

    setTotalAmount(
      Math.ceil(Math.ceil(data?.Fare?.TotalFare) * rateOfExchange)
    );
    setBasePrice(
      Math.ceil(
        data?.Fare?.BaseFare + data?.Fare?.OtherCharges * rateOfExchange
      )
    );
    if (totalTrips == 2) {
      const firstFlight = {
        ...data,
        Segments: [data?.Segments[0]],
      };
      const secondFlight = {
        ...data,
        Segments: [data?.Segments[1]],
      };

      dispatch(handleSelectedFlightGlobal([firstFlight, secondFlight]));
      setFlightDetails([firstFlight, secondFlight]);
      if (
        firstFlight?.IsPassportRequiredAtBook ||
        firstFlight?.IsPassportRequiredAtTicket
      )
        setIsPassportRequired(true);
      else if (
        secondFlight?.IsPassportRequiredAtBook ||
        secondFlight?.IsPassportRequiredAtTicket
      )
        setIsPassportRequired(true);
      else {
        // As per Akriti passport is mandatory for booking
        // setIsPassportRequired(false);
      }

      // const [firstDate] = multiCityDates.filter((date) => date.key === 1);
      setSelectedDate(multiCityDate1);
    } else if (totalTrips == 3) {
      const firstFlight = {
        ...data,
        Segments: [data?.Segments[0]],
      };
      const secondFlight = {
        ...data,
        Segments: [data?.Segments[1]],
      };
      const thirdFlight = {
        ...data,
        Segments: [data?.Segments[2]],
      };

      dispatch(
        handleSelectedFlightGlobal([firstFlight, secondFlight, thirdFlight])
      );
      setFlightDetails([firstFlight, secondFlight, thirdFlight]);
      if (
        firstFlight?.IsPassportRequiredAtBook ||
        firstFlight?.IsPassportRequiredAtTicket
      )
        setIsPassportRequired(true);
      else if (
        secondFlight?.IsPassportRequiredAtBook ||
        secondFlight?.IsPassportRequiredAtTicket
      )
        setIsPassportRequired(true);
      else if (
        thirdFlight?.IsPassportRequiredAtBook ||
        thirdFlight?.IsPassportRequiredAtTicket
      )
        setIsPassportRequired(true);
      else{ 
        // As per akriti passport is mandatory for booking
        // setIsPassportRequired(false);
      }
      // const [secondDate] = multiCityDates.filter((date) => date.key === 2);
      setSelectedDate(multiCityDate2);
    }
    handleChangePrice(updatedFare);
    handleGetCoupons(amount, markup, 0, base, airlineCodesArray);
  };

  const handleSelectedFlightForOneWay = (
    data,
    markupDetails,
    rateOfExchange
  ) => {

    const amount = Math.ceil(data[0]?.Fare?.TotalFare * rateOfExchange);
    const base =
      Math.ceil(data[0]?.Fare?.TotalFare * rateOfExchange) - (
        Math.ceil(data[0]?.Fare?.Tax * rateOfExchange) +
        Math.ceil(data[0]?.Fare?.VATAmount * rateOfExchange))
      ;
    console.log("data123", base)
    const airlineCodesArray = [
      data[0].Segments[0][0].AirlineDetails.AirlineCode,
    ];

    dispatch(handleSelectedFlightGlobal(data));
    setFlightDetails(data);
    const markup = getMarkup(data[0], markupDetails, rateOfExchange) || 0;
    setMarkup(markup);

    const updatedFare = [
      Math.ceil(data[0]?.Fare?.TotalFare * rateOfExchange) + markup,
    ];

    setTotalAmount(amount);
    setBasePrice(base);
    setTaxAmout(data[0]?.Fare?.Tax)
    setAirlineCodes([data[0].ValidatingAirline]);
    setFlightNumbers([data[0].Segments[0][0].AirlineDetails.FlightNumber]);

    if (
      data[0]?.IsPassportRequiredAtBook ||
      data[0]?.IsPassportRequiredAtTicket
    ) {
      setIsPassportRequired(true);
    } else{
      // As per akriti passport is always required
      //  setIsPassportRequired(false);
      }
    handleChangePrice(updatedFare);
    setSelectedDate(flightDepartureDate);
    handleGetCoupons(amount, markup, 0, base, airlineCodesArray);
  };

  const handleSelectedFlightForInternationalRoundTrip = (
    flight,
    markupDetails,
    rateOfExchange
  ) => {
    const data = flight[0];
    const amount = Math.ceil(data?.Fare?.TotalFare * rateOfExchange);

    const base =
      Math.ceil(data?.Fare?.TotalFare * rateOfExchange) -
      (Math.ceil(data?.Fare?.Tax * rateOfExchange) +
        Math.ceil(data?.Fare?.VATAmount * rateOfExchange))

    console.log("rateOfexchange", base);
    const airlineCodesArray = [data.Segments[0][0].AirlineDetails.AirlineCode];
    setAirlineCodes([data.ValidatingAirline]);
    setFlightNumbers([data.Segments[0][0].AirlineDetails.FlightNumber]);
    const markup = getMarkup(data, markupDetails, rateOfExchange) || 0;
    setMarkup(markup);
    const outboundFlight = {
      ...data,
      Segments: [data?.Segments[0]],
    };
    const inboundFlight = {
      ...data,
      Segments: [data?.Segments[1]],
    };
    dispatch(handleSelectedFlightGlobal([outboundFlight, inboundFlight]));
    setFlightDetails([outboundFlight, inboundFlight]);
    const updatedFare = [data?.Fare?.TotalFare * rateOfExchange + markup];
    setTaxAmout(data?.Fare?.Tax)
    setTotalAmount(amount);
    setBasePrice(base);
    if (
      outboundFlight?.IsPassportRequiredAtBook ||
      outboundFlight?.IsPassportRequiredAtTicket
    )
      setIsPassportRequired(true);
    else if (
      inboundFlight?.IsPassportRequiredAtBook ||
      inboundFlight?.IsPassportRequiredAtTicket
    )
      setIsPassportRequired(true);
    else {
      // As per akriti passport is always mandatory for booking
      // setIsPassportRequired(false);
    }
    handleChangePrice(updatedFare);
    setSelectedDate(flightReturnDate);
    handleGetCoupons(amount, markup, 0, base, airlineCodesArray);
  };

  const handleSelectedFlightForDomesticRoundTrip = (data, markupDetails) => {
    const amount = Math.ceil(
      data[0]?.Fare?.TotalFare + data[1]?.Fare?.TotalFare
    );
    const base = Math.ceil(
      data[0]?.Fare?.BaseFare +
      data[1]?.Fare?.BaseFare +
      data[0]?.Fare?.OtherCharges +
      data[1]?.Fare?.OtherCharges
    );
    const airlineCodesArray = [data[0].AirlineCode, data[1].AirlineCode];
    const markup = getMarkup(data[0], markupDetails);
    setMarkup(markup);
    const markup2 = getMarkup(data[1], markupDetails);
    setMarkup2(markup2);
    setAirlineCodes([data[0].ValidatingAirline, data[1].ValidatingAirline]);
    setFlightNumbers([
      data[0].Segments[0][0].Airline.FlightNumber,
      data[1].Segments[0][0].Airline.FlightNumber,
    ]);
    dispatch(handleSelectedFlightGlobal(data));
    setFlightDetails(data);
    const updatedFare = [
      data[0]?.Fare?.PublishedFare + markup,
      data[1]?.Fare?.PublishedFare + markup2,
    ];
    setTotalAmount(amount);
    setBasePrice(base);

    // As per akriti passport is always mandatory for booking
    // setIsPassportRequired(false);
    handleChangePrice(updatedFare);
    setSelectedDate(flightReturnDate);
    handleGetCoupons(amount, markup, markup2, base, airlineCodesArray);
  };

  const handleGetSSRdetails = async () => {
    const data = await getSsrDetails({
      traceId,
      resultIndex,
      isLcc,
      additionalDetails,
      token: user?.token,
    });

    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    if (statusCode == 400) {
      setErrorMessage(message);
      setSessionExpired(true);
    }

    if (statusCode == 500) {
      setServerError(true);
    }

    if (data) {
      let flag = false;
      let baggageArray = [];
      let extraBaggageArray = [];

      for (let i = 0; i < data?.length; i++) {
        let OutWard = [];
        if (data[i]?.SeatDynamic) {
          flag = true;
        }
        if (data[i]?.Baggage?.OutWard) {
          for (let j = 0; j < data[i]?.Baggage?.OutWard.length; j++) {
            baggageArray.push(data[i]?.Baggage.OutWard[j]);
            const { Origin, Destination } = data[i].Baggage.OutWard[0];
            const key = `${Origin}-${Destination}`;
            if (!extraBaggageArray[key]) {
              extraBaggageArray[key] = [];
            }

            extraBaggageArray[key].push();
          }
        }
      }
      for (let k = 0; k < data?.length; k++) {
        let arr = seats;
        let dummyArray = segmentSeats;
        let OutWard = [];
        if (data[k] && data[k].SeatDynamic && data[k].SeatDynamic.OutWard) {
          OutWard = data[k].SeatDynamic.OutWard;
        }
        for (let j = 0; j < OutWard?.length; j++) {
          dummyArray.push(OutWard[j]);
          const { Destination, Origin } = OutWard[j].RowSeats[0].SeatInfo[0];
          const key = `${Origin}-${Destination}`;
          if (!arr[key]) {
            arr[key] = [];
          }

          arr[key].push();
        }

        setSeats(arr);
        setSegmentSeats(dummyArray);
        setSeatsArrayBackup(arr);
      }

      if (!flag) {
        setSteps([
          t("stepper.chooseYourFlight"),
          t("stepper.enterYourDetails"),
          t("stepper.reviewBooking"),
        ]);
      }
      setHasSeatDynamic(flag);
      setExtraBaggage(extraBaggageArray);

      setAdditionalBaggageDetails(baggageArray);
    }
  };

  const handleChangePrice = (updatedFare) => {
    const prevFare = JSON.parse(pf);
    let hasFareChanged = false;
    setChangedFareValue(updatedFare);

    for (let i = 0; i < prevFare.length; i++) {
      const latestFare = Math.ceil(updatedFare[i]);

      if (latestFare && prevFare[i] && latestFare != prevFare[i]) {
        hasFareChanged = true;
      }
    }
    if (hasFareChanged) setShowChangePriceModal(true);
    else {
      setShowChangePriceModal(false);
    }
  };

  return (
    <>
      {sessionExpired ? (
        <SessionExpired message={errorMessage} />
      ) : serverError ? (
        <FallBackScreen />
      ) : flightDetails && Object.keys(flightDetails).length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <Rings
            height="150"
            width="150"
            color="#1B1D52"
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />{" "}
        </div>
      ) : (
        <>
          {screenNo === 1 ? (
            <FlightStepper activeStep={1} steps={steps} />
          ) : screenNo === 2 ? (
            <FlightStepper
              activeStep={steps.length === 4 ? 2 : 1}
              steps={steps}
            />
          ) : screenNo === 3 ? (
            <FlightStepper
              activeStep={steps.length === 4 ? 3 : 1}
              steps={steps}
            />
          ) : (
            <></>
          )}

          {screenNo === 3 ? (
            <FinalDetails
              flightDetails={flightDetails}
              insuranceDetails={insuranceDetails}
              totalAmount={totalAmount}
              convenienceFeeDetails={convenienceFeeDetails}
              markup={markup}
              markup2={markup2}
              taxAmount={taxAmount}
              convenienceFeeId={convenienceFeeId}
              setConvenienceFeeId={setConvenienceFeeId}
              basePrice={basePrice}
              setScreenNo={setScreenNo}
              isLCC={isLcc}
              markupId={markupId}
              isCouponApplied={isCouponApplied}
              setIsCouponApplied={setIsCouponApplied}
              discountValue={discountValue}
              setDiscountValue={setDiscountValue}
              message={message}
              setMessage={setMessage}
              applyInsurance={applyInsurance}
              extraBaggagePrice={calculateExtraBaggagePriceValue}
              seatPrice={seatPrice}
              tripType={tripType}
              couponDetails={couponDetails}
              resultIndex={resultIndex}
              extraBaggageDetails={extraBaggage}
              seatsDetails={seats}
              traceId={traceId}
              coupon={coupon}
              setCoupon={setCoupon}
              isInternational={isInternational}
            />
          ) : (
            <div className={styles.layoutContainer}>
              <div className={styles.layoutLeftContainer}>
                {screenNo === 1 && (
                  <>
                    <PersonalDetails
                      calculateExtraBaggagePriceValue={
                        calculateExtraBaggagePriceValue
                      }
                      setCalculateExtraBaggagePriceValue={
                        setCalculateExtraBaggagePriceValue
                      }
                      flightDetails={flightDetails}
                      additionalBaggageDetails={additionalBaggageDetails}
                      insuranceDetails={insuranceDetails}
                      extraBaggage={extraBaggage}
                      setExtraBaggage={setExtraBaggage}
                      isPassportRequired={isPassportRequired}
                      hasSeatDynamic={hasSeatDynamic}
                      applyInsurance={applyInsurance}
                      setApplyInsurance={setApplyInsurance}
                      selectedDate={selectedDate}
                      adults={adults}
                      infants={infants}
                      childCount={children}
                      tripType={tripType}
                      markup={markup}
                      markup2={markup2}
                      setScreenNo={setScreenNo}
                      seatPrice={seatPrice}
                      isCouponApplied={isCouponApplied}
                      discountValue={discountValue}
                      airlineCodes={airlineCodes}
                      traceId={traceId}
                      resultIndex={resultIndex}
                      markupId={markupId}
                      convenienceFeeDetails={convenienceFeeDetails}
                      couponDetails={couponDetails}
                      flightNumbers={flightNumbers}
                    />
                    <div style={{ marginBottom: "80px" }}>
                      {isMobile && (
                        <PrivateCoupon
                          couponDetails={couponDetails}
                          traceId={traceId}
                          setIsCouponApplied={setIsCouponApplied}
                          tripType={tripType}
                          flightDetails={flightDetails}
                          markup={markup}
                          markup2={markup2}
                          setDiscountValue={setDiscountValue}
                          coupon={coupon}
                          setCoupon={setCoupon}
                          isCouponApplied={isCouponApplied}
                          message={message}
                          setMessage={setMessage}
                          visibility={visibility}
                        />
                      )}
                    </div>
                  </>
                )}
                {screenNo === 2 && (
                  <>
                    <SeatDetails
                      flightDetails={flightDetails}
                      tripType={tripType}
                      markup={markup}
                      markup2={markup2}
                      setScreenNo={setScreenNo}
                      seats={seats}
                      segmentSeats={segmentSeats}
                      setSeatPrice={setSeatPrice}
                      setSeats={setSeats}
                      couponDetails={couponDetails}
                      traceId={traceId}
                      setIsCouponApplied={setIsCouponApplied}
                      setDiscountValue={setDiscountValue}
                      message={message}
                      setMessage={setMessage}
                      isCouponApplied={isCouponApplied}
                      seatsArrayBackup={seatsArrayBackup}
                    />
                  </>
                )}
              </div>
              {!isMobile && screenNo !== 3 && (
                <div className={styles.layoutRightContainer}>
                  {flightDetails &&
                    flightDetails.map((flight, i) => {
                      return (
                        <CartFlightDetails
                          flight={flight}
                          key={i}
                          selected={i}
                          markup={
                            tripType === "DRT"
                              ? i == 0
                                ? markup
                                : markup2
                              : markup
                          }
                        />
                      );
                    })}
                  {tripType === "DRT" ? (
                    <div className={styles.fareRuleContainerDRT}>
                      {flightDetails &&
                        flightDetails.map((flight, i) => {
                          return (
                            <>
                              <CartFareRules
                                flight={flight}
                                key={i}
                                markup={
                                  tripType === "DRT"
                                    ? i == 0
                                      ? markup
                                      : markup2
                                    : markup
                                }
                                insurance={insuranceDetails}
                                applyInsurance={applyInsurance}
                                setTotalAmount={setDummy}
                                setBaseAmount={setBaseAmount}
                                showWay={true}
                                calculateExtraBaggagePriceValue={
                                  calculateExtraBaggagePriceValue
                                }
                              />
                              {i === 0 && (
                                <Divider sx={{ backgroundColor: "black" }} />
                              )}
                            </>
                          );
                        })}
                      {applyInsurance && insuranceDetails && (
                        <div className={styles.insuranceContainer}>
                          <p className={styles.tripInsurance}>
                            {t("fareRuleCard.tripInsurance")}
                          </p>
                          <p className={styles.tripInsuranceAmount}>
                            {t("fareRuleCard.currencySymbol")}{" "}
                            {(
                              insuranceDetails.amount * rateOfExchange
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                      {calculateExtraBaggagePriceValue > 0 && (
                        <div className={styles.baggageContainer}>
                          <p className={styles.extraBaggage}>
                            {t("fareRuleCard.extraBaggage")}
                          </p>
                          <p className={styles.extraBaggagePrice}>
                            {t("fareRuleCard.currencySymbol")}{" "}
                            {Math.ceil(
                              calculateExtraBaggagePriceValue * rateOfExchange
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                      {seatPrice > 0 && (
                        <div className={styles.baggageContainer}>
                          <p className={styles.extraBaggage}>
                            {t("fareRuleCard.extraSeatPrice")}
                          </p>
                          <p className={styles.extraBaggagePrice}>
                            {t("fareRuleCard.currencySymbol")}{" "}
                            {Math.ceil(
                              seatPrice * rateOfExchange
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                      {isCouponApplied && (
                        <div className={styles.insuranceContainer}>
                          <p className={styles.tripInsurance}>
                            {t("fareRuleCard.discount")}
                          </p>
                          <p className={styles.tripInsuranceAmount}>
                            - {t("fareRuleCard.currencySymbol")}{" "}
                            {discountValue.toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                      <Divider mt={3} md={3} sx={{ borderStyle: "dashed" }} />
                      {totalAmount > 0 && (
                        <div className={styles.mainPriceContainer}>
                          <p className={styles.mainPriceHeading}>
                            {t("fareRuleCard.totalPrice")}
                          </p>
                          <p className={styles.mainpriceValue}>
                            {applyInsurance && insuranceDetails
                              ? `${t("fareRuleCard.currencySymbol")} ${(
                                Math.ceil(totalAmount) +
                                Math.ceil(
                                  insuranceDetails?.amount * rateOfExchange
                                ) +
                                Math.ceil(
                                  calculateExtraBaggagePriceValue *
                                  rateOfExchange
                                ) +
                                Math.ceil(seatPrice * rateOfExchange) +
                                markup +
                                markup2 -
                                discountValue
                              ).toLocaleString("en-IN")}`
                              : `${t("fareRuleCard.currencySymbol")} ${(
                                Math.ceil(totalAmount) +
                                Math.ceil(
                                  calculateExtraBaggagePriceValue *
                                  rateOfExchange
                                ) +
                                Math.ceil(seatPrice * rateOfExchange) +
                                markup +
                                markup2 -
                                discountValue
                              ).toLocaleString("en-IN")} `}
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    flightDetails &&
                    flightDetails?.length > 0 && (
                      <div className={styles.fareRuleContainer}>
                        {
                          <CartFareRules
                            flight={flightDetails[0]}
                            markup={
                              tripType === "DRT"
                                ? i == 0
                                  ? markup
                                  : markup2
                                : markup
                            }
                            insurance={insuranceDetails}
                            applyInsurance={applyInsurance}
                            setTotalAmount={setDummy}
                            setBaseAmount={setBaseAmount}
                            showWay={false}
                            calculateExtraBaggagePriceValue={
                              calculateExtraBaggagePriceValue
                            }
                          />
                        }
                        {applyInsurance &&
                          insuranceDetails &&
                          insuranceDetails?.amount && (
                            <div className={styles.insuranceContainer}>
                              <p className={styles.tripInsurance}>
                                {t("fareRuleCard.tripInsurance")}
                              </p>
                              <p className={styles.tripInsuranceAmount}>
                                {t("fareRuleCard.currencySymbol")}{" "}
                                {(
                                  insuranceDetails.amount * rateOfExchange
                                ).toLocaleString("en-IN")}
                              </p>
                            </div>
                          )}
                        {calculateExtraBaggagePriceValue > 0 && (
                          <div className={styles.baggageContainer}>
                            <p className={styles.extraBaggage}>
                              {t("fareRuleCard.extraBaggage")}
                            </p>
                            <p className={styles.extraBaggagePrice}>
                              {t("fareRuleCard.currencySymbol")}{" "}
                              {Math.ceil(
                                calculateExtraBaggagePriceValue * rateOfExchange
                              ).toLocaleString("en-IN")}
                            </p>
                          </div>
                        )}
                        {seatPrice > 0 && (
                          <div className={styles.baggageContainer}>
                            <p className={styles.extraBaggage}>
                              {t("fareRuleCard.extraSeatPrice")}
                            </p>
                            <p className={styles.extraBaggagePrice}>
                              {t("fareRuleCard.currencySymbol")}{" "}
                              {Math.ceil(
                                seatPrice * rateOfExchange
                              ).toLocaleString("en-IN")}
                            </p>
                          </div>
                        )}
                        {isCouponApplied && (
                          <div className={styles.insuranceContainer}>
                            <p className={styles.tripInsurance}>
                              {t("fareRuleCard.discount")}
                            </p>
                            <p className={styles.tripInsuranceAmount}>
                              - {t("fareRuleCard.currencySymbol")}{" "}
                              {discountValue.toLocaleString("en-IN")}
                            </p>
                          </div>
                        )}
                        <Divider mt={3} md={3} sx={{ borderStyle: "dashed" }} />
                        {totalAmount > 0 && (
                          <div className={styles.mainPriceContainer}>
                            <p className={styles.mainPriceHeading}>
                              {t("fareRuleCard.totalPrice")}
                            </p>
                            <p className={styles.mainpriceValue}>
                              {applyInsurance && insuranceDetails
                                ? ` ${t("fareRuleCard.currencySymbol")} ${(
                                  Math.ceil(totalAmount) +
                                  Math.ceil(insuranceDetails?.amount) +
                                  Math.ceil(
                                    calculateExtraBaggagePriceValue *
                                    rateOfExchange
                                  ) +
                                  Math.ceil(seatPrice * rateOfExchange) +
                                  markup +
                                  markup2 -
                                  discountValue
                                ).toLocaleString("en-IN")}`
                                : ` ${t("fareRuleCard.currencySymbol")} ${(
                                  Math.ceil(totalAmount) +
                                  Math.ceil(
                                    calculateExtraBaggagePriceValue *
                                    rateOfExchange
                                  ) +
                                  Math.ceil(seatPrice * rateOfExchange) +
                                  markup +
                                  markup2 -
                                  discountValue
                                ).toLocaleString("en-IN")} `}
                            </p>
                          </div>
                        )}
                      </div>
                    )
                  )}
                  <PrivateCoupon
                    couponDetails={couponDetails}
                    traceId={traceId}
                    setIsCouponApplied={setIsCouponApplied}
                    tripType={tripType}
                    flightDetails={flightDetails}
                    markup={markup}
                    markup2={markup2}
                    setDiscountValue={setDiscountValue}
                    coupon={coupon}
                    setCoupon={setCoupon}
                    isCouponApplied={isCouponApplied}
                    message={message}
                    setMessage={setMessage}
                    visibility={visibility}
                  />
                </div>
              )}
            </div>
          )}

          {isMobile &&
            screenNo === 2 &&
            (tripType === "DRT" ? (
              <div className={styles.container}>
                {showPriceAccordian && (
                  <div className={styles.fullFairDetailsContainer}>
                    <div className={styles.FairDetailsContainer}>
                      <p className={styles.fareDetails}>
                        {t("fareRuleCard.fareDetails")}
                      </p>
                      <div className={styles.baseFareAndTaxesFessContainer}>
                        <div className={styles.baseFareContainer}>
                          <p className={styles.baseFareText}>
                            {t("fareRuleCard.baseFare")}
                          </p>
                          <p className={styles.baseFare}>
                            {t("fareRuleCard.currencySymbol")}{" "}
                            {(
                              Math.ceil(
                                flightDetails[0].Fare.TotalFare * rateOfExchange
                              ) -
                              (Math.ceil(
                                flightDetails[0].Fare.Tax * rateOfExchange
                              ) +
                                Math.ceil(
                                  flightDetails[0].Fare.VATAmount *
                                  rateOfExchange
                                )) +
                              Math.ceil(
                                flightDetails[0].Fare.TotalFare * rateOfExchange
                              ) -
                              (Math.ceil(
                                flightDetails[1].Fare.Tax * rateOfExchange
                              ) +
                                Math.ceil(
                                  flightDetails[1].Fare.VATAmount *
                                  rateOfExchange
                                )) +
                              Math.ceil(
                                flightDetails[0].Fare.OtherCharges *
                                rateOfExchange
                              ) +
                              Math.ceil(
                                flightDetails[1]?.Fare.OtherCharges *
                                rateOfExchange
                              ) +
                              markup +
                              markup2
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                        <div className={styles.taxesAndFessContainer}>
                          <p className={styles.taxesAndFeesText}>
                            {t("fareRuleCard.taxesAndFees")}{" "}
                          </p>
                          <p className={styles.taxesAndFees}>
                            {t("fareRuleCard.currencySymbol")}{" "}
                            {(
                              Math.ceil(
                                flightDetails[0].Fare.Tax * rateOfExchange
                              ) +
                              Math.ceil(
                                flightDetails[1]?.Fare.Tax * rateOfExchange
                              )
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                        <div className={styles.taxesAndFessContainer}>
                          <p className={styles.taxesAndFeesText}>VAT </p>
                          <p className={styles.taxesAndFees}>
                            {t("fareRuleCard.currencySymbol")}{" "}
                            {(
                              Math.ceil(
                                flightDetails[0].Fare.VATAmount * rateOfExchange
                              ) +
                              Math.ceil(
                                flightDetails[1]?.Fare.VATAmount *
                                rateOfExchange
                              )
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                        {calculateExtraBaggagePriceValue > 0 && (
                          <div className={styles.taxesAndFessContainer}>
                            <p className={styles.taxesAndFeesText}>
                              {t("fareRuleCard.extraBaggage")}
                            </p>
                            <p className={styles.taxesAndFees}>
                              {t("fareRuleCard.currencySymbol")}{" "}
                              {Math.ceil(
                                calculateExtraBaggagePriceValue * rateOfExchange
                              ).toLocaleString("en-IN")}
                            </p>
                          </div>
                        )}
                        {applyInsurance &&
                          insuranceDetails &&
                          insuranceDetails?.amount && (
                            <div className={styles.taxesAndFessContainer}>
                              <p className={styles.taxesAndFeesText}>
                                {t("fareRuleCard.insurance")}
                              </p>
                              <p className={styles.taxesAndFees}>
                                {t("fareRuleCard.currencySymbol")}{" "}
                                {Math.ceil(
                                  insuranceDetails.amount * rateOfExchange
                                ).toLocaleString("en-IN")}
                              </p>
                            </div>
                          )}
                        {seatPrice > 0 && (
                          <div className={styles.taxesAndFessContainer}>
                            <p className={styles.taxesAndFeesText}>
                              {t("fareRuleCard.extraSeatPrice")}
                            </p>
                            <p className={styles.taxesAndFees}>
                              {t("fareRuleCard.currencySymbol")}{" "}
                              {(seatPrice * rateOfExchange).toLocaleString(
                                "en-IN"
                              )}
                            </p>
                          </div>
                        )}
                        {isCouponApplied && (
                          <div className={styles.taxesAndFessContainer}>
                            <p className={styles.taxesAndFeesText}>
                              {" "}
                              {t("fareRuleCard.discount")}
                            </p>
                            <p className={styles.taxesAndFees}>
                              - {t("fareRuleCard.currencySymbol")}{" "}
                              {discountValue.toLocaleString("en-IN")}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.fairContainer}>
                  <div style={{ display: "flex" }}>
                    <ExpandLessIcon
                      className={styles.expandIcon}
                      onClick={() => setShowPriceAccordian(!showPriceAccordian)}
                    />
                    <div className={styles.fairCardLayout}>
                      <div className={styles.fairLayout}>
                        <div className={styles.totalText}>
                          {t("fareRuleCard.totalIncludingTax")}
                        </div>
                        <div className={styles.fair}>
                          {applyInsurance && insuranceDetails
                            ? ` ${t("fareRuleCard.totalIncludingTax")} ${(
                              Math.ceil(
                                flightDetails[0].Fare.TotalFare *
                                rateOfExchange
                              ) +
                              Math.ceil(
                                flightDetails[1].Fare.TotalFare *
                                rateOfExchange
                              ) +
                              Math.ceil(
                                insuranceDetails?.amount * rateOfExchange
                              ) +
                              Math.ceil(
                                calculateExtraBaggagePriceValue *
                                rateOfExchange
                              ) +
                              Math.ceil(seatPrice * rateOfExchange) +
                              markup +
                              markup2 -
                              discountValue
                            ).toLocaleString("en-IN")}`
                            : ` ${t("fareRuleCard.totalIncludingTax")} ${(
                              Math.ceil(
                                flightDetails[0].Fare.TotalFare *
                                rateOfExchange
                              ) +
                              Math.ceil(
                                flightDetails[1].Fare.TotalFare *
                                rateOfExchange
                              ) +
                              Math.ceil(
                                calculateExtraBaggagePriceValue *
                                rateOfExchange
                              ) +
                              Math.ceil(seatPrice * rateOfExchange) +
                              markup +
                              markup2 -
                              discountValue
                            ).toLocaleString("en-IN")} `}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div onClick={() => setScreenNo(3)}>
                    <button className={styles.bookNowButton}>
                      {t("flightSearch.bookNow")}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.container}>
                {showPriceAccordian && (
                  <div className={styles.fullFairDetailsContainer}>
                    <div className={styles.FairDetailsContainer}>
                      <p className={styles.fareDetails}>
                        {t("fareRuleCard.fareDetails")}
                      </p>
                      <div className={styles.baseFareAndTaxesFessContainer}>
                        <div className={styles.baseFareContainer}>
                          <p className={styles.baseFareText}>
                            {t("fareRuleCard.baseFare")}
                          </p>
                          <p className={styles.baseFare}>
                            {t("fareRuleCard.currencySymbol")}{" "}
                            {(
                              Math.ceil(
                                flightDetails[0].Fare.TotalFare * rateOfExchange
                              ) -
                              (Math.ceil(
                                flightDetails[0].Fare.Tax * rateOfExchange
                              ) +
                                Math.ceil(
                                  flightDetails[0].Fare.VATAmount *
                                  rateOfExchange
                                )) +
                              Math.ceil(
                                flightDetails[0].Fare.OtherCharges *
                                rateOfExchange
                              ) +
                              markup
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                        <div className={styles.taxesAndFessContainer}>
                          <p className={styles.taxesAndFeesText}>
                            {t("fareRuleCard.taxesAndFees")}{" "}
                          </p>
                          <p className={styles.taxesAndFees}>
                            {t("fareRuleCard.currencySymbol")}{" "}
                            {Math.ceil(
                              flightDetails[0].Fare.Tax * rateOfExchange
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                        <div className={styles.taxesAndFessContainer}>
                          <p className={styles.taxesAndFeesText}>VAT </p>
                          <p className={styles.taxesAndFees}>
                            {t("fareRuleCard.currencySymbol")}{" "}
                            {Math.ceil(
                              flightDetails[0].Fare.VATAmount * rateOfExchange
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                        {calculateExtraBaggagePriceValue > 0 && (
                          <div className={styles.taxesAndFessContainer}>
                            <p className={styles.taxesAndFeesText}>
                              {t("fareRuleCard.extraBaggage")}
                            </p>
                            <p className={styles.taxesAndFees}>
                              {t("fareRuleCard.currencySymbol")}{" "}
                              {Math.ceil(
                                calculateExtraBaggagePriceValue * rateOfExchange
                              ).toLocaleString("en-IN")}
                            </p>
                          </div>
                        )}
                        {applyInsurance &&
                          insuranceDetails &&
                          insuranceDetails?.amount && (
                            <div className={styles.taxesAndFessContainer}>
                              <p className={styles.taxesAndFeesText}>
                                {t("fareRuleCard.insurance")}
                              </p>
                              <p className={styles.taxesAndFees}>
                                {t("fareRuleCard.currencySymbol")}{" "}
                                {Math.ceil(
                                  insuranceDetails.amount
                                ).toLocaleString("en-IN")}
                              </p>
                            </div>
                          )}
                        {seatPrice > 0 && (
                          <div className={styles.taxesAndFessContainer}>
                            <p className={styles.taxesAndFeesText}>
                              {t("fareRuleCard.extraSeatPrice")}
                            </p>
                            <p className={styles.taxesAndFees}>
                              {t("fareRuleCard.currencySymbol")}{" "}
                              {Math.ceil(
                                seatPrice * rateOfExchange
                              ).toLocaleString("en-IN")}
                            </p>
                          </div>
                        )}
                        {isCouponApplied && (
                          <div className={styles.taxesAndFessContainer}>
                            <p className={styles.taxesAndFeesText}>
                              {t("fareRuleCard.discount")}
                            </p>
                            <p className={styles.taxesAndFees}>
                              - {t("fareRuleCard.currencySymbol")}{" "}
                              {discountValue.toLocaleString("en-IN")}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.fairContainer}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {showPriceAccordian ? (
                      <ExpandLessIcon
                        className={styles.expandIcon}
                        onClick={() =>
                          setShowPriceAccordian(!showPriceAccordian)
                        }
                      />
                    ) : (
                      <ExpandMoreIcon
                        className={styles.expandIcon}
                        onClick={() =>
                          setShowPriceAccordian(!showPriceAccordian)
                        }
                      />
                    )}
                    <div className={styles.fairCardLayout}>
                      <div className={styles.fairLayout}>
                        <div className={styles.totalText}>
                          {" "}
                          {t("fareRuleCard.totalIncludingTax")}
                        </div>
                        <div className={styles.fair}>
                          {applyInsurance && insuranceDetails
                            ? `${t("fareRuleCard.currencySymbol")} ${(
                              Math.ceil(
                                flightDetails[0].Fare.TotalFare *
                                rateOfExchange
                              ) +
                              Math.ceil(insuranceDetails?.amount) +
                              Math.ceil(
                                calculateExtraBaggagePriceValue *
                                rateOfExchange
                              ) +
                              Math.ceil(seatPrice * rateOfExchange) +
                              markup +
                              markup2 -
                              discountValue
                            ).toLocaleString("en-IN")}`
                            : `${t("fareRuleCard.currencySymbol")} ${(
                              Math.ceil(
                                flightDetails[0].Fare.TotalFare *
                                rateOfExchange
                              ) +
                              Math.ceil(
                                calculateExtraBaggagePriceValue *
                                rateOfExchange
                              ) +
                              Math.ceil(seatPrice * rateOfExchange) +
                              markup +
                              markup2 -
                              discountValue
                            ).toLocaleString("en-IN")} `}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div onClick={() => setScreenNo(3)}>
                    <button className={styles.bookNowButton}>
                      {t("fareRuleCard.continueToFinalDetails")}
                    </button>
                  </div>
                </div>
              </div>
            ))}

          {showChangePriceModal && (
            <ChangePrice
              showChangePriceModal={showChangePriceModal}
              setShowChangePriceModal={setShowChangePriceModal}
              prevFare={pf}
              updatedFare={changedFareValue}
            />
          )}
        </>
      )}
    </>
  );
};

export default BookFlightDetailsFlow;
