import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { Box, Table, Typography } from "@mui/material";
import OneWaySvg from "../../../../assets/images/Line arrow-right.svg";
import RoundtripSvg from "../../../../assets/images/arrow-round-trip.svg";
import blueDotIcon from "../../../../assets/images/blue_dot.svg";
import lineGrey from "../../../../assets/images/Line_grey.svg";
import planeIcon from "../../../../assets/images/plane-line.svg";
import bulletsIcon from "../../../../assets/images/bullets_black.svg";
import PlaceholderImage from "../../../../assets/images/logo.png";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import RefreshSvg from "../../../../assets/images/refresh-cw-04.svg";
import InfoSvg from "../../../../assets/images/info-circle-blue.svg";
import {
  TimeFormat,
  flightDurationInHoursAndMinutes,
  formatDateToArabicDateAndMonthAndYearFormat,
} from "../../../../utils/helpers";
import { flightClassData, flightClassDataArabic } from "../../../../config";
import Tippy from "@tippy.js/react";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { formatDateToArabicDayDateAndMonthAndYearFormat } from "../../../../utils/helpers";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const SegmentDetails = ({ flightTicket, flightSearch, index, statusText }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [segmentDetails, setSegmentDetails] = useState([]);

  const {
    ticket: {
      Results: {
        Itinerary: {
          Segments,
          Origin,
          Destination,
          Passenger,
          JourneyType,
          NonRefundable,
          FareRules,
          PNR,
        },
      },
    },
  } = flightTicket;

  const { status } = flightTicket;

  const formatDate = (dateString) => {

    if(localStorage.getItem("language") != "AR"){
      const date = moment(dateString);
      const formattedDate = date.format("ddd, DD MMM YY");
      return formattedDate;
    }else{
      return formatDateToArabicDayDateAndMonthAndYearFormat(dateString)
    }
   
  };

  const formatDOB = (dateString) => {
    if(!dateString) return ""

    if(localStorage.getItem("language") != "AR"){
      const date = moment(dateString);
      const formattedDate = date.format("DD, MMM YYYY");
      return formattedDate;
    }else{
      return formatDateToArabicDateAndMonthAndYearFormat(dateString)
    }
 
  };

  const formatTime = (inputDate) => {
    const formattedDate = moment(inputDate).format("HH:mm");
    return formattedDate;
  };

  const getSeat = (SeatDynamic, originCityCode, destCityCode) => {
    const filteredSeat = SeatDynamic.filter((seat) => {
      return (
        seat.Origin === originCityCode && seat.Destination === destCityCode
      );
    });

    if (filteredSeat.length > 0) {
      const { Code } = filteredSeat[0];
      return Code;
    } else return "-";
  };
console.log("passenger", Passenger)
  const getBaggage = (Baggage, originCityCode, destCityCode) => {
    const filteredBaggage = Baggage.filter((baggage) => {
      return (
        baggage.Origin === originCityCode &&
        baggage.Destination === destCityCode
      );
    });

    if (filteredBaggage.length > 0) {
      const { Weight } = filteredBaggage[0];
      return `${Weight} KG`;
    } else return "-";
  };

  useEffect(() => {
    const result = [];
    const flightSegments = flightSearch[0].flightSegments;
    let j = 0;
    for (let i = 0; i < flightSegments.length; i++) {
      const flightSegment = flightSegments[i];
      const key = `${flightSegment.origin}-${flightSegment.destination}`;
      const matchingSegments = [];

      while (j < Segments.length) {
        if (index === 1 && i == 0) break;
        j++;
        matchingSegments.push(Segments[j - 1]);
        if (
          j + 1 < Segments.length &&
          Segments[j + 1].Origin.AirportCode === flightSegment.destination
        ) {
          matchingSegments.push(Segments[j]);
          j++;
          break;
        }

        if (
          Segments[j - 1].Destination.AirportCode === flightSegment.destination
        ) {
          break;
        }
      }
      result.push({
        [key]: matchingSegments,
      });
    }

    setSegmentDetails(result);
  }, []);

  return (
    <>
      <div className={classes.tripInfoContainer}>
        <div className={classes.flightInfoContent1}>
          <div>
            <div className={classes.fligtInfo}>
              <p className={classes.city}>{Origin}</p>

              {JourneyType == 2 ? (
                <ReactSVG src={RoundtripSvg} />
              ) : localStorage.getItem("language") == "AR" ? (
                <ArrowBackIcon className={classes.journeyIcon} />
              ) : (
                <ReactSVG src={OneWaySvg} className={classes.journeyIcon} />
              )}

              <p className={classes.city}>{Destination}</p>
            </div>
            <p className={classes.subInfo}>
              {Passenger[0].FirstName.charAt(0).toUpperCase() +
                Passenger[0].FirstName.slice(1)}{" "}
              {t("bookingConfirmationFlight.wasTraveling")}
            </p>
          </div>
          <div className={classes.statusButton}>
            <p className={classes.statusText}>{statusText}</p>
          </div>
        </div>
        <div className={classes.pnrText}>
          <span className={classes.pnrHeading}>
            {t("bookingConfirmationFlight.PNR")}: &nbsp;</span>
          {PNR}
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        {segmentDetails &&
          segmentDetails.map((segment, i) => {
            const key = Object.keys(segment)[0];

            const value = segment[key];

            return (
              value &&
              value.length > 0 && (
                <>
                  <p>{key}</p>
                  {value &&
                    value.map((segmentInfo) => {
                      const {
                        IncludedBaggage,
                        CabinBaggage,
                        CabinClass,
                        // AirlineDetails: {
                        //   AirlineCode,
                        //   AirlineName,
                        //   FlightNumber,
                        // },
                        FlightNumber,
                        Airline,
                        AirlineName,
                        DepartureTime,
                        ArrivalTime,
                        Origin: {
                          CityCode: originCityCode,
                          AirportName: originAirportName,
                          Terminal: originTerminal,
                        },
                        Destination: {
                          CityCode: destCityCode,
                          AirportName: destAirportName,
                          Terminal: destTerminal,
                        },
                        Duration,
                      } = segmentInfo;

                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            background: "#FAFAFA",
                            border: "1px solid #D0D5DD",
                            boxShadow: "0 1px 4px 0 rgba(0,0,0,.21)",
                          }}
                        >
                          <Box className={classes.segmentContainer}>
                            <Box className={classes.airlineContainer}>
                              <img
                                src={`${S3Bucket}/AIRLINE_ICON/${Airline}.png`}
                                onError={(e) => {
                                  e.target.src = PlaceholderImage;
                                }}
                                alt="flightLogo"
                                style={{
                                  height: "36px",
                                  width: "42px",
                                }}
                              />
                              <Box>
                                <Typography
                                  sx={{
                                    color: "#344054",
                                    textAlign: "center",
                                    fontFamily: "Lato",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "20px",
                                  }}
                                >
                                  {AirlineName}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#667085",
                                    fontFamily: "Lato",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                  }}
                                >
                                  {Airline}-{FlightNumber}
                                </Typography>
                              </Box>
                            </Box>
                            <Box className={classes.segmentFlightInfoContainer}>
                              <Box className={classes.flightInfoFromContainer}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato",
                                      fontSize: "18px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      textAlign: "center",
                                    }}
                                  >
                                    {originCityCode} {formatTime(DepartureTime)}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#667085",
                                      fontFamily: "Lato",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      textAlign: "center",
                                    }}
                                  >
                                    {formatDate(DepartureTime)}
                                  </Typography>
                                </Box>
                                <Typography
                                  sx={{
                                    color: "#667085",
                                    fontFamily: "Lato",
                                    fontSize: "14px",
                                    fontstyle: "normal",
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  {originTerminal && (
                                    <span
                                      style={{
                                        color: "#344054",
                                        fontFamily: "Lato",
                                        fontSize: "14px",
                                        fontstyle: "normal",
                                        fontWeight: 600,
                                        textAlign: "center",
                                      }}
                                    >
                                      T-{`${originTerminal}, `}
                                    </span>
                                  )}
                                  {originAirportName}
                                </Typography>
                              </Box>
                              <Box
                                className={classes.flightInfoDurationContainer}
                              >
                                <Typography
                                  sx={{
                                    color: "#667085",
                                    textAlign: "center",
                                    fontFamily: "Lato",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                  }}
                                >
                                  {TimeFormat(Duration)}
                                </Typography>
                                <Box
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <ReactSVG
                                    src={blueDotIcon}
                                    style={{
                                      marginBottom: "6px",
                                    }}
                                  />
                                  <ReactSVG
                                    src={lineGrey}
                                    style={{
                                      marginBottom: "6px",
                                    }}
                                  />
                                  <ReactSVG src={planeIcon} />
                                  <ReactSVG
                                    src={lineGrey}
                                    style={{
                                      marginBottom: "6px",
                                    }}
                                  />
                                  <ReactSVG
                                    src={blueDotIcon}
                                    style={{
                                      marginBottom: "6px",
                                    }}
                                  />
                                </Box>
                                <Typography
                                  sx={{
                                    color: "#667085",
                                    textAlign: "center",
                                    fontFamily: "Lato",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                  }}
                                >
                                  {localStorage.getItem("language") == "AR"
                                    ? flightClassDataArabic[CabinClass]
                                    : flightClassData[CabinClass]}
                                </Typography>
                              </Box>
                              <Box className={classes.flightInfoToContainer}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato",
                                      fontSize: "18px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      textAlign: "center",
                                    }}
                                  >
                                    {formatTime(ArrivalTime)} {destCityCode}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#667085",
                                      fontFamily: "Lato",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      textAlign: "center",
                                    }}
                                  >
                                    {formatDate(ArrivalTime)}
                                  </Typography>
                                </Box>

                                <Typography
                                  sx={{
                                    color: "#667085",
                                    fontFamily: "Lato",
                                    fontSize: "14px",
                                    fontstyle: "normal",
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  {destTerminal && (
                                    <span
                                      style={{
                                        color: "#344054",
                                        fontFamily: "Lato",
                                        fontSize: "14px",
                                        fontstyle: "normal",
                                        fontWeight: 600,
                                      }}
                                    >
                                      T-{`${destTerminal}, `}
                                    </span>
                                  )}
                                  {destAirportName}
                                </Typography>
                              </Box>
                            </Box>
                            <Tippy
                              arrow={true}
                              placement="bottom-end"
                              animation="shift-away"
                              interactive={true}
                              content={
                                FareRules[i]?.FareRuleDetail ? (
                                  <div className={classes.tooltipContainer}>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: FareRules[i]?.FareRuleDetail,
                                      }}
                                    />
                                  </div>
                                ) : (()=>{
                                  console.log('FareRules', FareRules)
                                  // return "Nothing"
                                })()
                              }
                            >
                              <div className={classes.refundableStatus}>
                                <ReactSVG src={RefreshSvg} />
                                {NonRefundable
                                  ? `${t(
                                    "bookingConfirmationFlight.nonRefundable"
                                  )}`
                                  : `${t(
                                    "bookingConfirmationFlight.refundable"
                                  )}`}
                                <ReactSVG src={InfoSvg} />
                              </div>
                            </Tippy>
                          </Box>
                          {IncludedBaggage || CabinBaggage ? (
                            <Box
                              sx={{
                                display: "flex",
                                padding: "16px",
                                alignItems: "flex-start",
                                gap: "33px",
                                borderBottom: "1px solid #D0D5DD",
                              }}
                            >
                              <Typography
                                sx={{
                                  width: "105px",
                                  color: "#667085",
                                  fontFamily: "Lato",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                }}
                              >
                                {t("bookingConfirmationFlight.baggageDetails")}
                              </Typography>
                              {IncludedBaggage && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: "8px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#667085",
                                      fontFamily: "Lato",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t("bookingConfirmationFlight.checkIn")}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {IncludedBaggage} (
                                    {t("bookingConfirmationFlight.perAdult")})
                                  </Typography>
                                </Box>
                              )}
                              {CabinBaggage && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: "8px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#667085",
                                      fontFamily: "Lato",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t("bookingConfirmationFlight.cabin")}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {CabinBaggage} (
                                    {t("bookingConfirmationFlight.perAdult")})
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            <></>
                          )}
                          <div className={classes.tableContainer}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {t(
                                      "bookingConfirmationFlight.travellerName"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {/* Pax id */}
                                    {t(
                                      "bookingConfirmationFlight.paxID"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {t("bookingConfirmationFlight.ageGroup")}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {t("bookingConfirmationFlight.dateOfBirth")}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {t("bookingConfirmationFlight.nationality")}

                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {t("addContactDetailsScreen.documentTypes")}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {t("addContactDetailsScreen.idNumber")}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {t(
                                      "bookingConfirmationFlight.ticketNumber"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {t("bookingConfirmationFlight.seat")}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {t(
                                      "bookingConfirmationFlight.extraBaggage"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#344054",
                                      fontFamily: "Lato, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {t("bookingConfirmationFlight.status")}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Passenger &&
                                  Passenger.map((passenger) => {
                                    const {
                                      Title,
                                      FirstName,
                                      LastName,
                                      Type,
                                      DateOfBirth,
                                      Nationality,
                                      PaxId,
                                      PaxSeat,
                                      PaxBaggage,
                                    } = passenger;

                                    return (
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          align="left"
                                          sx={{
                                            color: "#344054",
                                            fontFamily: "Lato, sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {Type == 1
                                            ? `${Title}.`
                                            : Title == "Mr"
                                              ? "Master"
                                              : "Miss"}{" "}
                                          {FirstName} {LastName}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{
                                            color: "#344054",
                                            fontFamily: "Lato, sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {PaxId}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{
                                            color: "#344054",
                                            fontFamily: "Lato, sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {Type == 1
                                            ? "Adult"
                                            : Type == 2
                                              ? "Child"
                                              : "Infant"}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{
                                            color: "#344054",
                                            fontFamily: "Lato, sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {formatDOB(DateOfBirth)}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{
                                            color: "#344054",
                                            fontFamily: "Lato, sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {Nationality.CountryCode}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{
                                            color: "#344054",
                                            fontFamily: "Lato, sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {passenger?.IdDetails[0].IdType
                                                ? passenger.IdDetails[0].IdType === 1
                                                ? t("bookingConfirmationFlight.passportNumber")
                                                : passenger.IdDetails[0].IdType === 2
                                                ? t("bookingConfirmationFlight.nationalNumber")
                                                : passenger.IdDetails[0].IdType === 3
                                                ? t("bookingConfirmationFlight.iqamaNumber")
                                                : "-"
                                                : "-"}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{
                                            color: "#344054",
                                            fontFamily: "Lato, sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {passenger?.IdDetails[0].IdNumber
                                            ? passenger.IdDetails[0].IdNumber
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{
                                            color: "#344054",
                                            fontFamily: "Lato, sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {PNR}
                                        </TableCell>
                                        <TableCell align="left">
                                          <p
                                            style={{
                                              color: "#344054",
                                              fontFamily: "Lato, sans-serif",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {PaxSeat && PaxSeat.length > 0
                                              ? getSeat(
                                                PaxSeat,
                                                originCityCode,
                                                destCityCode
                                              )
                                              : "-"}
                                          </p>
                                        </TableCell>
                                        <TableCell align="left">
                                          <p
                                            style={{
                                              color: "#344054",
                                              fontFamily: "Lato, sans-serif",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {PaxBaggage && PaxBaggage.length > 0
                                              ? getBaggage(
                                                PaxBaggage,
                                                key.split("-")[0],
                                                key.split("-")[1]
                                              )
                                              : "-"}
                                          </p>
                                        </TableCell>
                                        <TableCell align="left">
                                          {status == "CONFIRMED" ? (
                                            <p
                                              style={{
                                                color: "#1DAC08",
                                                fontFamily: "Lato, sans-serif",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              Booked
                                            </p>
                                          ) : (
                                            <p
                                              style={{
                                                color: "red",
                                                fontFamily: "Lato, sans-serif",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {status}
                                            </p>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </div>
                        </Box>
                      );
                    })}
                </>
              )
            );
          })}
      </Box>
    </>
  );
};

export default SegmentDetails;
