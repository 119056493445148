import React, { useEffect, useState } from "react";
import bookingConfirmBottom from "../../../assets/images/booking-confirm-bottom-icon.svg";
import bookingConfirmationTop from "../../../assets/images/booking-confirmation-top.svg";
import bookingConfirmIcon from "../../../assets/images/booking confirm_icon (1).svg";
import { useNavigate, useParams } from "react-router-dom";
import useStyles from "./style";
import { getBookingDetails } from "../../../modules/flight";
import { useSelector } from "react-redux";
import FlightBookingConfirmation from "../../../components/flight-module/cards/booking-confirmation-content";
import AlertSvg from "../../../assets/images/alertSvg.svg";
import { ReactSVG } from "react-svg";
import PrintSvg from "../../../assets/images/printer.svg";
import DownloadSvg from "../../../assets/images/download.svg";
import ShareSvg from "../../../assets/images/share.svg";
import ArrowbackSvg from "../../../assets/images/arrow-left.svg";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import { setUserInfo } from "../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import ShareModal from "../../../components/flight-module/cards/share-modal";
import eventBus from "../../../core/eventBus";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";


const BookingConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { id, status } = useParams();
  const user = useSelector((state) => state.user);
  const [bookingDetails, setBookingDetails] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setIsErrorMessage] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  useEffect(() => {
    handleGetbookingDetails();
  }, []);

  const handleGetbookingDetails = async () => {
    const res = await getBookingDetails(id, user?.token);
    const statusCode = res?.statusCode;
    const message = res?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }
    if (res.statusCode === 200) {
      const { flightTickets } = res.data[0];
      if(res.data[0].ticketStatus === 4) {
        toast.success(t("successMessages.ticketShared"))
      }
      for (let i = 0; i < flightTickets?.length; i++) {
        const { ticket } = flightTickets[i];
        if (ticket?.statusCode === 400) {
          setIsError(true);
          setIsErrorMessage(ticket.customMessage);
        }
      }
      setBookingDetails(res.data);
      if (res?.data[0]) {
        if (res.data[0]?.ticketPdf) {
          setPdfUrl(res.data[0].ticketPdf);
        }
      }
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "ticket.pdf";
    link.target = "_blank";
    link.click();
  };

  const handlePrint = () => {
    const pdfWindow = window.open();
    pdfWindow.document.write(
      `<iframe src="${pdfUrl}" frameborder="0" width="100%" height="100%"></iframe>`
    );
    pdfWindow.document.close();
    // pdfWindow.print();
    pdfWindow.onafterprint = () => pdfWindow.close();
  };

  const handleShare = () => {
    eventBus.emit("open-bottomSheet", <ShareModal pdfUrl={pdfUrl} />);
  };

  return status == "FAILED" ? (
    <div className={classes.mainContainerError}>
      <div className={classes.titleContainer}>
        <img src={AlertSvg} className={classes.titleIcon} />
        <p className={classes.title}>
          {t("bookingConfirmationFlight.bookingIssueMessage")}
        </p>
      </div>
    </div>
  ) : isError == false && bookingDetails && bookingDetails.length > 0 ? (
    <>
      <div className={classes.mainContainer}>
        <div
          className={classes.headerLinkContainer}
          onClick={() => navigate("/bookings")}
        >
          <ReactSVG src={ArrowbackSvg} />
          {t("bookingConfirmationFlight.backToBookings")}
        </div>
        <div
          className={classes.confirmationImage}
          style={{ backgroundImage: `url(${bookingConfirmationTop})` }}
        >
          <img src={bookingConfirmIcon} className={classes.confirmationIcon} />
          <p className={classes.confirmationText}>
            {t("bookingConfirmationFlight.bookingConfirmed")}
          </p>
        </div>
        <FlightBookingConfirmation bookingDetails={bookingDetails} />
        <div
          className={classes.bookingConfirmationBottom}
          style={{
            backgroundImage: `url(${bookingConfirmBottom})`,
          }}
        ></div>
        <div className={classes.actionButtonComponent}>
          <div className={classes.actionButton} onClick={handleDownload}>
            <ReactSVG src={PrintSvg} />
            <p className={classes.actionButtonText}>
              {t("bookingConfirmationFlight.print")}
            </p>
          </div>
          <div className={classes.actionButton} onClick={handleDownload}>
            <ReactSVG src={DownloadSvg} />
            <p className={classes.actionButtonText}>
              {t("bookingConfirmationFlight.download")}
            </p>
          </div>
          {isMobile && (
            <div className={classes.actionButton} onClick={() => handleShare()}>
              <ReactSVG src={ShareSvg} />
              <p className={classes.actionButtonText}>
                {t("bookingConfirmationFlight.share")}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <div className={classes.mainContainerError}>
      <div className={classes.titleContainer}>
        <img src={AlertSvg} className={classes.titleIcon} />
        <p className={classes.title}>{errorMessage}</p>
      </div>
    </div>
  );
};

export default BookingConfirmation;
