import { createSlice } from "@reduxjs/toolkit";

const addPersonalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState: {
    renderCountryDropDown: true,
    defaultPassport: "Passport",
    defaultNational: "National",
    showPassportDetails: false,
    showNationalDetails: true,
    showIqamaDetails: false
  },
  reducers: {
    setRenderCountryDropDown: (state, action) => {
      state.renderCountryDropDown = action.payload;
    },
    setDefaultPassport: (state, action) => {
        state.defaultPassport = action.payload;
      },
      setDefaultNational: (state, action) => {
        state.defaultNational = action.payload;
      },
      setShowPassportDetails: (state, action) => {
        state.showPassportDetails = action.payload;
      },
    setShowNationalDetails: (state, action) => {
        state.showNationalDetails = action.payload;
      },
    setShowIqamaDetails: (state, action) => {
        state.showIqamaDetails = action.payload;
      },
  },
});

export const {
    setRenderCountryDropDown,
    setDefaultPassport,
    setDefaultNational,
    setShowPassportDetails,
    setShowNationalDetails,
    setShowIqamaDetails
} = addPersonalDetailsSlice.actions;

export default addPersonalDetailsSlice.reducer;
