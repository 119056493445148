import React, { useState, useEffect } from "react";
import nationality from "../../../../config/nationality.json";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";
import "./style.css";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import useStyles from "./styles";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Calendar } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InputAdornment from "@mui/material/InputAdornment";
import { setDefaultNational, setDefaultPassport, setRenderCountryDropDown, setShowIqamaDetails, setShowNationalDetails, setShowPassportDetails } from "../../../../utils/slices/personalDetailsSlice";
dayjs.extend(customParseFormat);

const AdultTitleData = [
  { key: "Mr", value: "Mr." },
  { key: "Ms", value: "Ms." },
  { key: "Mrs", value: "Mrs." },
];

const AdultTitleDataArabic = [
  { key: "Mr", value: "السيد" },
  { key: "Ms", value: "الآنسة" },
  { key: "Mrs", value: "السيدة" },
];

const childrenAndInfantTitleData = [
  { key: "Mr", value: "Master" },
  { key: "Ms", value: "Miss" },
];

const childrenAndInfantTitleDataArabic = [
  { key: "Mr", value: "السيد" },
  { key: "Ms", value: "الآنسة" },
];

const customStyles = {
  borderRadius: "10px",
  padding: "10px 4px",
};

const calendarCustomStyles = {
  "& .MuiPickersDay-root": {
    backgroundColor: "red", // Change this to the desired color
    color: "white", // Change this to the desired text color
  },
  "& .MuiPickersDay-daySelected:hover": {
    backgroundColor: "darkblue", // Change this to the desired hover color
  },
};

const AddPersonalDetails = ({
  values,
  setValues,
  index,
  isPassportRequired,
  paxType,
  selectedDate,
  travellerDetailsError,
  setTravellerDetailsError,
  isDOBRequired
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [day, month, year] = selectedDate.split("/");
  const travelDate = dayjs(`${year}-${month}-${day}`, { format: "YYYY-MM-DD" });
  const [titleData, setTitleData] = useState(
    paxType == 1 ? AdultTitleData : childrenAndInfantTitleData
  );
  const getLocale = useSelector((state) => state.locale);
  const [titleDataArabic, setTitledataArabic] = useState(
    paxType == 1 ? AdultTitleDataArabic : childrenAndInfantTitleDataArabic
  );
  const dispatch = useDispatch();
  const [title, setTitle] = useState(values?.title);
  const [middleNameError, setMiddleNameError] = useState("");
  const [showDateOfBirth, setShowDateOfBirth] = useState(false);
  const [showExpirtyCalendar, setShowExpiryCalendar] = useState(false);
  const [showPassportIssueCalendar, setShowPassportIssueCalendar] = useState(false);
  const [showNationalExpiryCalender, setShowNationalExpiryCalender] = useState(false);
  const [showIqamaExpiryCalender, setShowIqamaExpiryCalender] = useState(false);
  const renderCountryDropDown = useSelector((state) => state.personalDetails.renderCountryDropDown);
  const defaultPassport = useSelector((state) => state.personalDetails.defaultPassport);
  const defaultNational = useSelector((state) => state.personalDetails.defaultNational);
  const showPassportDetails = useSelector((state) => state.personalDetails.showPassportDetails);
  const showNationalDetails = useSelector((state) => state.personalDetails.showNationalDetails);
  const showIqamaDetails = useSelector((state) => state.personalDetails.showIqamaDetails);
  const passportIssueRequire = localStorage.getItem("passportFullDetailRequired") === "true"; 
  const international = localStorage.getItem("flightInternational");
  console.log("hrgfbjd", international)
  const sixMonthsNext = travelDate.add(7, "month").toDate();
  const todayDate = dayjs();  
  const oneMonthsBefores = todayDate.subtract(1, "month").toDate();

  const oneDayBeforeTravelDate = todayDate.subtract(1, "day").toDate();
  const oneDayAfterTravelDate = travelDate.add(1, "day").toDate();
  const twelveYearsAgo = todayDate.subtract(12, "year").toDate();
  const twoYearsAgo = todayDate
    .subtract(2, "year")
    .subtract(1, "day")
    .toDate();

  const twoYearsAgoInfant = travelDate.subtract(2, "year").toDate();

  const getMaxDate = (paxType) => {
    console.log("getMaxDate called", getMaxDate);
    if (paxType === 1) {
      console.log("twelveYearsAgo", twelveYearsAgo);
      return twelveYearsAgo;
    } else if (paxType === 2) {
      console.log("twoYearsAgo", twoYearsAgo);
      return twoYearsAgo;
    } else if (paxType === 3) {
      console.log("returning dayjs().toDate();", oneMonthsBefores);
      return oneMonthsBefores;
    }
    console.log("returning ", dayjs().toDate());
    return oneMonthsBefores;
  };

  const getMinDate = (paxType) => {
    console.log("getMinDate Called", getMinDate);
    if (paxType === 2) {
      console.log("twelveYearsAgo", twelveYearsAgo);
      return twelveYearsAgo;
    } else if (paxType === 3) {
      console.log("twoYearsAgoInfant", twoYearsAgoInfant);
      return twoYearsAgoInfant;
    }
  };

  const getMinDateForPassportExpiry = () => {
    return sixMonthsNext;
  };

  const getMinDateForPassportIssue = () => {
    return oneDayBeforeTravelDate;
  }
  const getMinDateForNationalExpiry = () => {
  return oneDayAfterTravelDate;
  }

  useEffect(() => {
    setValues((prev) => {
      const updatedArray = [...prev];
      updatedArray[index]["paxType"] = paxType;
      return updatedArray;
    });
  }, []);

  const valueofDate = (date) => {
    const [day, month, year] = date.split("/");
    const formattedDate = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10)
    );
    return formattedDate;
  };

  const handleChange = (name, value) => {
    if (name === "dateOfBirth") {
      value = format(value, "dd/MM/yyyy");
    }
    if (name === "expiryDate") {
      value = format(value, "dd/MM/yyyy");
    }
    if (name === "passportIssueDate") {
      value = format(value, "dd/MM/yyyy");
    }
    if (name === "nationalIdExpiry") {
      value = format(value, "dd/MM/yyyy");
    }
    if (name === "iqamaIdExpiry") {
      value = format(value, "dd/MM/yyyy");
    }
    if (name === "title") {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["title"] = value;
        return updatedArray;
      });
    } else if (name === "firstName") {
      validateFirstName(value);
    } else if (name === "middleName") {
      validateMiddleName(value);
    } else if (name === "lastName") {
      validateLastName(value);
    } else if (name === "expiryDate") {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["expiryDate"] = value;
        return updatedArray;
      });
    }else if (name === "passportIssueDate") {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["passportIssueDate"] = value;
        return updatedArray;
      });
    } else if (name === "nationalIdExpiry") {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["nationalIdExpiry"] = value;
        return updatedArray;
      });
    } else if (name === "iqamaIdExpiry") {
      setValues((prev) => {
              const updatedArray = [...prev];
              updatedArray[index]["iqamaIdExpiry"] = value;
              return updatedArray;
            });
          }
    else if (name === "dateOfBirth") {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["dateOfBirth"] = value;
        return updatedArray;
      });
    }else if (name === "documentType") {
      dispatch(setDefaultPassport(value))
      if(value === "Passport") {
        dispatch(setShowPassportDetails(true))
            dispatch(setShowNationalDetails(false))
            dispatch(setShowIqamaDetails(false))
      } else if (value === "Iqama"){
        dispatch(setShowPassportDetails(false))
        dispatch(setShowNationalDetails(false))
        dispatch(setShowIqamaDetails(true))
       } else{
        dispatch(setShowPassportDetails(false))
      dispatch(setShowNationalDetails(true))
        dispatch(setShowIqamaDetails(false))
       }
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["documentType"] = value;
        return updatedArray;
      });
  } else if (name === "documentDetailType") {
    dispatch(setDefaultNational(value))
    if (value === "Passport") {
      dispatch(setShowPassportDetails(true))
      dispatch(setShowNationalDetails(false))
    } else {
      dispatch(setShowPassportDetails(false))
      dispatch(setShowNationalDetails(true))
    }
    setValues((prev) => {
      const updatedArray = [...prev];
      updatedArray[index]["documentDetailType"] = value;
      return updatedArray;
    });
  }
   else if (name === "nationality") {

      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["nationality"] = value;
       
        const selectedNationality = nationality.find(
          (option) => option.countryName === value
        );
        updatedArray[index]["nationalityCode"] =
          selectedNationality.countryCode;
          localStorage.setItem("countryCode", selectedNationality.countryCode)
          
          if (selectedNationality.countryCode !== "SA") {
            dispatch(setDefaultPassport("Passport"))
            dispatch(setRenderCountryDropDown(false))
            dispatch(setShowPassportDetails(true))
            dispatch(setShowNationalDetails(false))
            dispatch(setShowIqamaDetails(false))
            } else {
              dispatch(setDefaultNational("National"))
            dispatch(setRenderCountryDropDown(true))
            dispatch(setShowPassportDetails(false))
            dispatch(setShowNationalDetails(true))
            dispatch(setShowIqamaDetails(false))
          }
        return updatedArray;
      });
    } else if (name === "passportNumber") {
      validatePassportNumber(value);
    } else if (name === "nationalNumber") {
      validateNationalNumber(value);
    } else if (name === "iqamaNumber") {
      validateIqamaNumber(value);
    } else if (name === "iqamaIssuingCountry") {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["iqamaIssuingCountry"] = value;
        return updatedArray;
      });
    }
    else if (name === "issuingCountry") {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["issuingCountry"] = value;
        return updatedArray;
      });
    }
    setShowDateOfBirth(false);
    setShowExpiryCalendar(false);
    setShowPassportIssueCalendar(false)
  };

  const validatePassportNumber = (value) => {
    // const regex = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;
    if (!value) {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["passportNumber"] = value;
        return updatedArray;
      });
      // setTravellerDetailsError((prev) => {
      //   const updatedArray = [...prev];
      //   updatedArray[index]["passportError"] = `${t(
      //     "addContactDetailsScreen.passportIsRequired"
      //   )}`;
      //   return updatedArray;
      // });
    }
    else {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["passportNumber"] = value;
        return updatedArray;
      });
      // setTravellerDetailsError((prev) => {
      //   const updatedArray = [...prev];
      //   updatedArray[index]["passportError"] = "";
      //   return updatedArray;
      // });
    }
  };

  const validateNationalNumber = (value) => {
    // const regex = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;
    if (!value) {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["nationalNumber"] = value;
        return updatedArray;
      });
      setTravellerDetailsError((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["nationalNumberError"] = `${t(
          "addContactDetailsScreen.nationalNumberRequired"
        )}`;
        return updatedArray;
      });
    }
    else {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["nationalNumber"] = value;
        return updatedArray;
      });
      setTravellerDetailsError((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["nationalNumberError"] = "";
        return updatedArray;
      });
    }
  };

  const validateIqamaNumber = (value) => {
    // const regex = /^1[A-Za-z0-9]{9}$/; 
  
    if (!value) {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["iqamaNumber"] = value;
        return updatedArray;
      });
      // setTravellerDetailsError((prev) => {
      //   const updatedArray = [...prev];
      //   updatedArray[index]["iqamaNumberError"] = `${t(
      //     "addContactDetailsScreen.iqamaNumberRequired"
      //   )}`;
      //   return updatedArray;
      // });
    } 
    // else if (!regex.test(value)) {
    //   setTravellerDetailsError((prev) => {
    //     const updatedArray = [...prev];
    //     updatedArray[index]["iqamaNumberError"] = `${t(
    //       "addContactDetailsScreen.invalidIqamaNumber"
    //     )}`;
    //     return updatedArray;
    //   });
    // } 
    else {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["iqamaNumber"] = value;
        return updatedArray;
      });
      // setTravellerDetailsError((prev) => {
      //   const updatedArray = [...prev];
      //   updatedArray[index]["iqamaNumberError"] = "";
      //   return updatedArray;
      // });
    }
  };
  

  const validateFirstName = (value) => {
    const regex = /^[A-Za-z\u0600-\u06FF\s]{2,}$/;
    if (!value) {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["isFirstNameErrorExists"] = true;
        updatedArray[index]["firstName"] = value;
        return updatedArray;
      });
      setTravellerDetailsError((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["firstNameError"] = `${t(
          "addContactDetailsScreen.firstNameIsRequired"
        )}`;
        return updatedArray;
      });
    } else if (!regex.test(value)) {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["isFirstNameErrorExists"] = true;
        updatedArray[index]["firstName"] = value;
        return updatedArray;
      });
      setTravellerDetailsError((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["firstNameError"] = `${t(
          "addContactDetailsScreen.enterValidName"
        )}`;
        return updatedArray;
      });
    } else {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["isFirstNameErrorExists"] = false;
        updatedArray[index]["firstName"] = value;
        return updatedArray;
      });
      setTravellerDetailsError((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["firstNameError"] = "";
        return updatedArray;
      });
    }
  };

  const validateMiddleName = (value) => {
    const regex = /^(?:[A-Za-z\s]{2,}|[\u0600-\u06FF\s]{2,})$/;
    if (value === "") {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["isMiddleNameErrorExists"] = false;
        updatedArray[index]["middleName"] = value;
        return updatedArray;
      });
      setMiddleNameError("");
    } else if (!regex.test(value)) {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["isMiddleNameErrorExists"] = true;
        updatedArray[index]["middleName"] = value;
        return updatedArray;
      });
      setMiddleNameError(`${t("addContactDetailsScreen.enterValidName")}`);
    } else {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["isMiddleNameErrorExists"] = false;
        updatedArray[index]["middleName"] = value;
        return updatedArray;
      });
      setMiddleNameError("");
    }
  };

  const validateLastName = (value) => {
    const regex = /^(?:[A-Za-z\s]{2,}|[\u0600-\u06FF\s]{2,})$/;
    if (!value) {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["isLastNameErrorExists"] = true;
        updatedArray[index]["lastName"] = value;
        return updatedArray;
      });
      setTravellerDetailsError((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["lastNameError"] = `${t(
          "addContactDetailsScreen.lastNameIsRequired"
        )}`;
        return updatedArray;
      });
    } else if (!regex.test(value)) {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["isLastNameErrorExists"] = false;
        updatedArray[index]["lastName"] = value;
        return updatedArray;
      });
      setTravellerDetailsError((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["lastNameError"] = `${t(
          "addContactDetailsScreen.enterValidName"
        )}`;
        return updatedArray;
      });
    } else {
      setValues((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["isLastNameErrorExists"] = false;
        updatedArray[index]["lastName"] = value;
        return updatedArray;
      });
      setTravellerDetailsError((prev) => {
        const updatedArray = [...prev];
        updatedArray[index]["lastNameError"] = "";
        return updatedArray;
      });
    }
  };

  const styles = useStyles();

  useEffect(() => {
    const elements = document.querySelectorAll(".rdrNextPrevButton");
    if (localStorage.getItem("language") === "AR") {
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.transform = "rotate(180deg)"
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }else{
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }
  });
  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleDataContainer}>
        {localStorage.getItem("language") == "AR"
          ? titleDataArabic.map((data) => {
              const { key, value } = data;
              return (
                <p
                  key={key}
                  className={
                    title === key
                      ? styles.buttonBackgroundActive
                      : styles.buttonBackgroundNonActive
                  }
                  onClick={() => {
                    setTitle(key);
                    handleChange("title", key, index);
                  }}
                >
                  {value}
                </p>
              );
            })
          : titleData.map((data) => {
              const { key, value } = data;
              return (
                <p
                  key={key}
                  className={
                    title === key
                      ? styles.buttonBackgroundActive
                      : styles.buttonBackgroundNonActive
                  }
                  onClick={() => {
                    setTitle(key);
                    handleChange("title", key, index);
                  }}
                >
                  {value}
                </p>
              );
            })}
      </div>

      <div className={styles.nameContainerRow1}>
        <div className={styles.firstNameContainer}>
          <p className={styles.firstNameText}>
            {t("addContactDetailsScreen.firstName")}
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </p>
          <TextField
            size="small"
            placeholder={t("addContactDetailsScreen.enterYourFirstName")}
            className={classes.root}
            name="firstName"
            value={values?.firstName}
            onChange={(e) => handleChange(e.target.name, e.target.value, index)}
            InputProps={{
              style: customStyles,
            }}
            error={travellerDetailsError[index].firstNameError}
            helperText={travellerDetailsError[index].firstNameError}
            fullWidth
          ></TextField>
        </div>
        <div className={styles.middleNameContainer}>
          <p className={styles.middleNameText}>
            {t("addContactDetailsScreen.middleName")}
          </p>
          <TextField
            size="small"
            placeholder={t("addContactDetailsScreen.enterYourMiddleName")}
            className={classes.root}
            name="middleName"
            value={values?.middleName}
            onChange={(e) => handleChange(e.target.name, e.target.value, index)}
            InputProps={{
              style: customStyles,
            }}
            error={middleNameError}
            helperText={middleNameError}
            fullWidth
          ></TextField>
        </div>
      </div>

      <div className={styles.nameContainerRow2}>
        <div className={styles.lastNameContainer}>
          <p className={styles.lastNameText}>
            {t("addContactDetailsScreen.lastName")}
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </p>
          <TextField
            size="small"
            placeholder={t("addContactDetailsScreen.enterYourLastName")}
            className={classes.root}
            name="lastName"
            value={values?.lastName}
            onChange={(e) => handleChange(e.target.name, e.target.value, index)}
            InputProps={{
              style: customStyles,
            }}
            fullWidth
            error={travellerDetailsError[index].lastNameError}
            helperText={travellerDetailsError[index].lastNameError}
          ></TextField>
        </div>
        <div className={styles.dobContainer}>
          <>
            <p className={styles.dobText}>
              {t("addContactDetailsScreen.dateOfBirth")}
              <span style={{ color: "red", marginLeft: "2px" }}>
                {isDOBRequired && "*"}
              </span>
            </p>

            <TextField
              placeholder={t("addContactDetailsScreen.dateOfBirthPlaceHolder")}
              onClick={() => {
                setShowDateOfBirth(!showDateOfBirth);
              }}
              value={values.dateOfBirth ? values.dateOfBirth : null}
              InputProps={{
                // style: customStyles,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              }}
              disabled={true}
            ></TextField>
            {showDateOfBirth && (
              <Calendar
                className={classes.root}
                value={
                  values.dateOfBirth ? valueofDate(values.dateOfBirth) : null
                }
                onChange={(date) => handleChange("dateOfBirth", date, index)}
                maxDate={getMaxDate(paxType)}
                minDate={getMinDate(paxType)}
                locale={
                  localStorage.getItem("language") == "AR"
                    ? locales["arSA"]
                    : locales["enUS"]
                }
                date={
                  values.dateOfBirth
                    ? valueofDate(values.dateOfBirth)
                    : getMaxDate(paxType)
                }
              />
            )}
            {!values.dateOfBirth &&
              travellerDetailsError[index].dateOfBirthError && (
                <p
                  style={{
                    color: "#d32f2f",
                    fontSize: "12px",
                    fontWeight: 300,
                  }}
                >
                  {travellerDetailsError[index].dateOfBirthError}
                </p>
              )}
          </>
        </div>
      </div>

      <div className={styles.nationalityContainer}>
        <p className={styles.nationalityText}>
          {/* Nationality */}
          {t("addContactDetailsScreen.nationality")}
          <span style={{ color: "red", marginLeft: "2px" }}>
            { "*"}
          </span>
        </p>
        <TextField
          className={classes.root}
          fullWidth
          size="small"
          select
          name="nationality"
          value={values?.nationality}
          onChange={(e) => {
            setTravellerDetailsError((prev) => {
              const updatedArray = [...prev];
              updatedArray[index]["nationalityError"] = "";
              return updatedArray;
            });
            handleChange(e.target.name, e.target.value, index);
          }}
          // label="Select Nationality"
          label={t("addContactDetailsScreen.selectNationality")}
          SelectProps={{ native: true }}
          InputLabelProps={{
            style: { textAlign: "center", marginTop: "10px" },
          }}
          InputProps={{ style: customStyles }}
          error={travellerDetailsError[index].nationalityError}
          helperText={travellerDetailsError[index].nationalityError}
        >
          {nationality.map((option, i) => (
            <option key={i} value={option.countryName}>
              {option.countryName}
            </option>
          ))}
          ;
        </TextField>
      </div>

      {(
  <div className={styles.nationalityContainer}>
    <p className={styles.nationalityText}>
      {t("addContactDetailsScreen.documentDetailType")}
      <span style={{ color: "red", marginLeft: "2px" }}>*</span>
    </p>
    <TextField
      className={classes.root}
      fullWidth
      size="small"
      select
      name="documentDetailType"
      value={values?.documentDetailType || "National"}  
      onChange={(e) => handleChange(e.target.name, e.target.value, index)}
      label={t("addContactDetailsScreen.selectDocumentType")}
      SelectProps={{ native: true }}
      InputLabelProps={{
        style: { textAlign: "center", marginTop: "10px" },
      }}
      InputProps={{ style: customStyles }}
    >
      {localStorage.getItem("flightInternational") === "false" ? (
      <>
        {!(values?.nationalityCode && values.nationalityCode !== "SA") && (
          <option value="National">
            {t("addContactDetailsScreen.national")}
          </option>
        )}
        <option value="Passport">{t("addContactDetailsScreen.passport")}</option>
        {values?.nationalityCode && values.nationalityCode !== "SA" && (
          <option value="Iqama">{t("addContactDetailsScreen.iqama")}</option>
        )}
      </>
    ) : (
      <option value="Passport">{t("addContactDetailsScreen.passport")}</option>
    )}
    </TextField>
  </div>
)}

      {<div className={styles.passportDetailContainerRow1}>
        <div className={styles.passportNumberDetailContainer}>
          <p className={styles.passportNumberText}>
            {/* Passport Number */}
            {t("addContactDetailsScreen.idNumber")}
           <span style={{ color: "red", marginLeft: "2px" }}>
              { "*"}
            </span>
          </p>
          <TextField
            size="small"
            // placeholder="Passport Number"
            placeholder={t("addContactDetailsScreen.idNumber")}
            className={classes.root}
            fullWidth
            InputProps={{ style: customStyles }}
            name="passportNumber"
            value={values?.passportNumber}
            onChange={(e) => {
              setTravellerDetailsError((prev) => {
                const updatedArray = [...prev];
                updatedArray[index]["nationalityError"] = "";
                return updatedArray;
              });
              handleChange(e.target.name, e.target.value, index);
            }}
            // error={travellerDetailsError[index].passportError}
            // helperText={travellerDetailsError[index].passportError}
          />
        </div>
        <div className={styles.issuingCountryDetailContainer}>
          <p className={styles.issuingCountryText}>
            {/* Issuing Country */}
            {t("addContactDetailsScreen.issuingCountry")}
             <span style={{ color: "red", marginLeft: "2px" }}>
              { "*"}
            </span>
          </p>
          <TextField
            className={classes.root}
            fullWidth
            size="small"
            select
            label={t("addContactDetailsScreen.selectIssuingCountry")}
            name="issuingCountry"
            value={values?.issuingCountry}
            onChange={(e) => {
              // setTravellerDetailsError((prev) => {
              //   const updatedArray = [...prev];
              //   updatedArray[index]["issuingCountryError"] = "";
              //   return updatedArray;
              // });
              handleChange(e.target.name, e.target.value, index);
            }}
            SelectProps={{ native: true }}
            InputLabelProps={{
              style: { textAlign: "center", marginTop: "10px" },
            }}
            InputProps={{ style: customStyles }}
            // error={travellerDetailsError[index].issuingCountryError}
            // helperText={travellerDetailsError[index].issuingCountryError}
          >
            {nationality.map((option, i) => (
              <option key={i} value={option.countryName}>
                {option.countryName}
              </option>
            ))}
            ;
          </TextField>
        </div>
      </div>}

      {<div className={styles.expiryDateContainerRow}>
        <div className={styles.expiryDateContainer}>
          <p className={styles.expiryDateText}>
            {/* Expiry Date */}
            {t("addContactDetailsScreen.expiryDate")}
            <span style={{ color: "red", marginLeft: "2px" }}>
              { "*"}
            </span>
          </p>

          <TextField
            placeholder={t("addContactDetailsScreen.expiryDate")}
            onClick={() => {
              setShowExpiryCalendar(!showExpirtyCalendar);
            }}
            value={values.expiryDate ? values.expiryDate : null}
            InputProps={{
              // style: customStyles,
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarMonthIcon />
                </InputAdornment>
              ),
            }}
            disabled={true}
            // error={travellerDetailsError[index].expiryDateError}
          ></TextField>
          {showExpirtyCalendar && (
            <Calendar
              className={classes.root}
              value={values.expiryDate ? valueofDate(values.expiryDate) : null}
              onChange={(date) => handleChange("expiryDate", date, index)}
              minDate={getMinDateForPassportExpiry()}
              locale={
                localStorage.getItem("language") == "AR"
                  ? locales["arSA"]
                  : locales["enUS"]
              }
              date={
                values.expiryDate
                  ? valueofDate(values.expiryDate)
                  : getMinDateForPassportExpiry()
              }
            />
          )}

          {/* {!values.expiryDate ||
            (travellerDetailsError[index].expiryDateError && (
              <p
                style={{
                  color: "#d32f2f",
                  fontSize: "12px",
                  fontWeight: 300,
                }}
              >
                {travellerDetailsError[index].expiryDateError}
              </p>
            ))} */}
        </div>
        <div className={styles.expiryDateContainer}>
        <p className={styles.expiryDateText}>
            {/* Expiry Date */}
            {t("addContactDetailsScreen.passportIssueDate")}
            <span style={{ color: "red", marginLeft: "2px" }}>
              { "*"}
            </span>
          </p>

          <TextField
            placeholder={t("addContactDetailsScreen.passportIssueDate")}
            onClick={() => {
              setShowPassportIssueCalendar(!showPassportIssueCalendar);
            }}
            value={values.passportIssueDate ? values.passportIssueDate : null}
            InputProps={{
              // style: customStyles,
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarMonthIcon />
                </InputAdornment>
              ),
            }}
            disabled={true}
          ></TextField>
          {showPassportIssueCalendar && (
            <Calendar
              className={classes.root}
              value={values.passportIssueDate ? valueofDate(values.passportIssueDate) : null}
              onChange={(date) => handleChange("passportIssueDate", date, index)}
              maxDate={getMinDateForPassportIssue()}
              locale={
                localStorage.getItem("language") == "AR"
                  ? locales["arSA"]
                  : locales["enUS"]
              }
              date={
                values.passportIssueDate
                  ? valueofDate(values.passportIssueDate)
                  : null
              }
            />
          )}
          {/* {!values.passportIssueDate ||
            (travellerDetailsError[index].passportIssueDateError && (
              <p
                style={{
                  color: "#d32f2f",
                  fontSize: "12px",
                  fontWeight: 300,
                }}
              >
                {travellerDetailsError[index].passportIssueDateError}
              </p>
            ))} */}
        </div>
         </div>}
    </div>
  );
};

export default AddPersonalDetails;

AddPersonalDetails.propTypes = {
  values: PropTypes.object,
  setValues: PropTypes.func,
  index: PropTypes.number,
  isPassportRequired: PropTypes.bool,
  paxType: PropTypes.number,
};
